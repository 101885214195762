export class Transaction {
  tenderType: string;
  amount: string;
  result: string;
  transResult: string;
  userKey:string;

  /*detail*/
  orderNum: string;
  orderGuid: string;
  paymentType: string;
  transType: string;
  cardNum: string;
  cardType: string;
  authAmt: string;
  approvedAmt: string;
  tipAmt: string;  // list
  cashBackAmt: string;
  taxAmt: string;
  surchargeAmt: string;
  totalAmt: string;
  fuelAmt: string;
  terminalSN: string;
  terminalRefNum: string;
  terminalTransDate: string;
  terminalTransTime: string;
  resultCode: string;
  resultMsg: string;
  transResultCode: string;
  transResultMsg: string;
  approvalCode: string;
  transDate: string;
  traceNum: string;
  ecrRefNum: string;
  hostRefNum: string;
  hostCode: string;
  hostToken: string;
  hostMsg: string;
  batchNum: string;
  voidFlag: string;

  origTransNum: string;
  email: string;
  user: string;
  userDeleted:boolean;


  entryMode: string;
  signatureData: string;

  discountAmt: string;
  returnFlag: string;
  returnAmt: string;
  returnTipAmt: string;
  note: string;
  description: string;
  timeZone: string;

  reversalFlag: string;
  cardPresentFlag: string;
  
  status: string; // list 

  /*customerRef: string;
  expDate: string;
  ipAddress: string;
  invoiceNum: string;
  merchantName: string;
  nameOnCard: string;
  origTransDate: string;
  origTransID: string;
  origTransTime: string;
  poNum: string;
  posID: string;
  processor: string;
  registerNum: string;
  requestDate: string;
  responseDate: string;
  reversalFlag: string;
  safFlag: string;
  serverID: string;
  settleFlag: string;
  settleKey: string;
  settleMsg: string;
  signDate: string;
  street: string;
  terminalID: string;
  transactionID: string;
  trxLogKey: string;
  uploadFlag: string;
  zip: string;*/

}
