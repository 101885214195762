import { Component, OnInit,TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { Modal } from 'src/app/utils/modal.service';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent implements OnInit {
    @Input() modal: Modal
    @Input() modalTitileContent: boolean = false;
    @Input() dialogHeight:number = 50;
    @Input() title: string
    @Input() cancelText: string
    @Input() confirmText: string
    @Input() showConfirmButton: boolean = true
    @Output() confirmEventEmit = new EventEmitter();
    @Output() cancelEventEmit = new EventEmitter();
    
  constructor() {
  }

  ngOnInit() {
  }

  cancelEvent() {
    this.cancelEventEmit.emit();
  }

  confirmEvent() {
    this.confirmEventEmit.emit();
  }

}
