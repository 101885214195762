import {AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, AfterViewInit {

  constructor(@Inject('options') private options) {
  }

  title: string;
  msg: string;
  buttons: Array<any>;
  staticBackdrop: boolean;
  delay: boolean;
  delayTime: number;
  delayTimeHandler: any;
  template: TemplateRef<any>;


  @ViewChild('container', {read: ViewContainerRef, static: false}) container: ViewContainerRef;

  ngOnInit() {
    let options = this.options;
    this.title = options.title;
    this.msg = options.msg;
    this.buttons = options.buttons;
    this.staticBackdrop = (options.staticBackdrop);
    if (options.delay) {
      this.setDelay(options.delayTime);
    }
    if (options.template) {
      this.template = options.template;
    }
    if (options.cssClass) {
      $('.modal-dialog').addClass(options.cssClass);
      // $('.modal-dialog').addClass('modal-dialog-lg');
    }
  }

  ngAfterViewInit() {
    if (this.template) {
      setTimeout(() => {
        this.container.createEmbeddedView(this.template);
      });
    }
  }

  handler(handler) {
    if (handler) {
      handler();
    }
  }

  setDelay(delayTime) {
    let time = () => {
      this.delayTimeHandler = setTimeout(() => {
        this.delayTime--;
        if (!this.delayTime) {
          console.log('dismiss');
        } else {
          time();
        }
      }, 1000);
    };

    this.delay = true;
    this.delayTime = delayTime;
    time();
  }

}
