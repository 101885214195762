import {Injectable} from '@angular/core';
import {Response} from '../models/response';
import {HttpService} from './http.service';
import {DateService} from '../utils/date.service';
import {Order} from '../models/order';
import {OrderLine} from '../models/orderLine';
import { Pagination } from '../models/pagination';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class OrderService {
  store_id;

  constructor(private http: HttpService, private authServce: AuthService) {
    this.store_id = this.authServce.getSession('store_id')
  }
  static toOrderModel(res) {
    return new Order({
      createdDate: DateService.getDateAndTimeString(DateService.utcToLocal(res['CreatedDate'])),
      orderGuid: res['OrderGuid'],
      ecrrefNum: res['ECRRefNum'],
      totalAmt: res['TotalAmt'],
      subTotal: res['SubTotal'],
      taxAmt: res['TaxAmt'],
      discountAmt: res['DiscountAmt'],
      tipAmt: res['TipAmt'],
      surchargeAmt: res['SurchargeAmt'],
      orderName: res['OrderName'],
      originalOrderGuid: res['OriginalOrderGuid'],
      orderStatus: res['OrderStatus'],
      paymentStatus: res['PaymentStatus'],
      refundedAmt: res['RefundedAmt'],
      nonCashAmt: res['NonCashAmt'],
      deliveryAmt: res['DeliveryAmt'],
      specialInstructions: res['SpecialInstructions'],
      createdBy: res['CreatedBy'],
      // orderLines: OrderService.toOrderLinesModel(res['OrderLines']),
    });
  }

  // https://portal-server-dev.up.railway.app/v1/stores/{store_id}/orders/

  getOrderList(param): Promise<Response<Pagination<any>>> {
    this.store_id = this.authServce.getSession('store_id')
    let response: Response<Pagination<any>> = new Response<Pagination<any>>();
    return new Promise((resolve, reject)=> {
      this.http.get(`/railwayPortal/stores/${this.store_id}/orders/`,{
        restful: param
      }).subscribe(res => {
        let page: Pagination<any> = new Pagination<any>();
        page.data = res.order_result_list
        page.totalCounts = res.count;
        page.currPage = res.page_index + 1;
        page.pageSize = res.page_size;
        page.totalPage = Math.ceil(page.totalCounts / page.pageSize);

        response.data = page
        resolve(response);
      }, error => reject(error));
    })
  }

  updateOrderStatus(OrderGuid): Promise<Response<any>> {
    
    let response: Response<any> = new Response<any>();
    return new Promise((resolve, reject)=> {
      
      this.http.put(`/railwayPortal/orders/${OrderGuid}/update_order_read_status`, {
        is_read: true
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    })
  }
  addOrderToPos(OrderGuid): Promise<Response<any>> {
    
    let response: Response<any> = new Response<any>();
    return new Promise((resolve, reject)=> {
      // https://portal-server-dev.up.railway.app/v1/orders/{order_id}/add_to_POS
      this.http.put(`/railwayPortal/orders/${OrderGuid}/add_to_POS`).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    })
  }

  getOrderDetail(OrderGuid): Promise<Response<any>> {
    // https://portal-server-dev.up.railway.app/v1/orders/{order_id}
    let response: Response<any> = new Response<any>();
    return new Promise((resolve, reject)=> {
      this.http.get(`/railwayPortal/orders/${OrderGuid}`).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    })
  }

  timeFun: any;
}
