import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BuildMenu} from '../../constants/menu';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {expendAnim, rotateAnim} from '../../animations/common';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [expendAnim, rotateAnim]
})

export class LeftMenuComponent implements OnInit, OnDestroy {

  menus;
  routerEventsListener: Subscription;
  @Input() shrink: boolean;
  @Output() hideLeftMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isPc: boolean;

  constructor(public router: Router, public activatedRoute: ActivatedRoute, public authService: AuthService) {
    this.menus = BuildMenu(this.authService.getAuthority());
    this.listenRouter();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.routerEventsListener) this.routerEventsListener.unsubscribe();
  }

  listenRouter(): void {
    this.routerEventsListener = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe((res: any) => {
      this.highLightItem(res.urlAfterRedirects);
    });
  }

  highLightItem(url) {
    this.menus.forEach((menu) => {
      if (menu.child.length) {
        menu.child.forEach((submenu) => {
          submenu.active = url.includes(submenu.router);
          // submenu.active = submenu.router === url;
          if (submenu.active) {
            menu.active = true;
          }
        });
      } else {
        const isActive = menu.activeRouteList.findIndex(item => url.includes(item)) != -1;
        
        menu.active = menu.router === url || isActive;
      }
    });
  }

  changeMenu(menu) {
    menu.active = !menu.active;
  }

  openMenu(menu) {
    menu.active = true;
  }
  hide() {
    this.isPc ? '' :this.hideLeftMenu.emit(true);
  }
}
