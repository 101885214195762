import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,ElementRef, OnDestroy} from '@angular/core';
import {Constant} from '../../constants/constant';
import { GeneralService } from 'src/app/utils/general.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {

  @Input() pageSize;
  @Output() pageSizeChange = new EventEmitter();

  @Input() totalPage: number;

  @Input() currPage: number = 5;
  @Output() currPageChange = new EventEmitter();

  @Output() loadChange = new EventEmitter();
  @Output() private onChangePage = new EventEmitter();

  showPages: Array<number> = [];
  display: { pre: number, total: number } = {
    pre: this.generalService.isPC() ? Constant.ui_pagination_pre : Constant.ui_mobile_pagination_pre,
    total: this.generalService.isPC() ? Constant.ui_pagination_total - 1: Constant.ui_mobile_pagination_total - 1
  };

  pageSizeList: Array<number> = Constant.ui_pagination_pageSizeList;
  isPcChange: Subscription

  constructor(private ele: ElementRef, private generalService: GeneralService) { }

  ngOnInit() {
    // this.listen();
    this.isPcChange = this.generalService.getPCAgentChanges().subscribe(isPC => {

      this.display = {
        pre: isPC ? Constant.ui_pagination_pre : Constant.ui_mobile_pagination_pre,
        total: isPC ? Constant.ui_pagination_total - 1: Constant.ui_mobile_pagination_total - 1
      };

      this.pagination();
    });
  }

  ngOnDestroy(): void {
      
  }

  change() {
    var tds=document.getElementsByClassName('page-drop');
    tds[0]['style'].setProperty('transform', `translate3d(0px, -${this.pageSizeList.length * 40 + 1}px, 0px) !important`)
    // tds[0]['style'].transform = `translate3d(0px, -${this.pageSizeList.length * 40 + 1}px, 0px) !important`;
    console.log(tds[0]['style'].top, '???')
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pagination();
  }

  _pageSizeChange = () => {
    this.pageSizeChange.emit(this.pageSize);
    this.currPageChange.emit(1);
    this.onChangePage.emit();
  };

  _currPageChange = (page) => {
    this.currPageChange.emit(page);
    this.onChangePage.emit();
  };

  pagination = () => {
    let page = this.currPage;
    let display = this.display;
    let totalPages = this.totalPage;

    let min = page - display.pre;
    if (min <= 0) min = 1;

    let max = min + display.total;
    if (max > totalPages) {
      max = totalPages;
      min = max - display.total;
      if (min <= 0) min = 1;
    }

    let showPages: Array<number> = [];
    for (let i = min; i <= max; i++) showPages.push(i);

    this.showPages = showPages;
  };

  listen() {
    let divEle = this.ele.nativeElement.querySelector('.dropdown-menu');
    divEle.addEventListener('scroll',this.scroll,true)
  }
 
  scroll=(e)=>{
    if(this.currPage == this.totalPage) this.removeListen(); 
    if (e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight) {
      this.currPage ++;
      this.currPageChange.emit(this.currPage);
    }
  }

  removeListen(){
    let divEle = this.ele.nativeElement.querySelector('.dropdown-menu')
    divEle.removeEventListener('scroll', this.scroll, true);
  }
  select(size: any):void {
    this.pageSize = size
    this.pageSizeChange.emit(this.pageSize);
    this._pageSizeChange();
  }

}
