import { Injectable } from '@angular/core';
import { Response } from '../models/response';
import { Constant } from '../constants/constant';
import { HttpService } from './http.service';
import { SettingConfig, SettingField } from '../models/settingConfig';

@Injectable({
  providedIn: 'root'
})
export class SettingConfigService {

  constructor(private http: HttpService) {
  }

  static toSettingConfigModel(res): Array<SettingConfig> {
    let settingConfigLists: Array<SettingConfig> = [];
    for (let setting of res) {
      let settingConfig = new SettingConfig();
      settingConfig.groupName = setting['GroupName'];
      settingConfig.open = setting['Open'];
      for (let config of setting['SettingFieldList']) {
        let settingField = new SettingField();
        settingField.dataType = config['DataType'];
        if (config['DataType'] === 'bool' && config['ConfigValue'] != null) {
          settingField.configValue = config['ConfigValue'].toLowerCase() === 'true';
        } else settingField.configValue = config['ConfigValue'];
        settingField['confirmConfigValue'] = settingField.configValue;
        settingField.description = config['Description'];
        settingField.displayName = config['DisplayName'];
        settingField.fieldName = config['FieldName'];
        settingField.fieldType = config['FieldType'];
        settingField.maxLength = config['MaxLength'];
        settingField.required = config['Required'];
        settingField.regEx = config['RegEx'];
        settingField.errorMessage = config['ErrorMessage'];
        settingField.requiredConfirm = config['RequiredConfirm'];
        settingField.merchantConfigFieldKey = config['MerchantConfigFieldKey'];
        settingField.isDisplay = config['IsDisplay'];     

        settingField.dependFieldKey = config['DependFieldKey'];
        if (settingField.dependFieldKey) settingField.dependValue = config['DependValue'].toLowerCase() === 'true';

        if (config['SelectList']) {  // dropdown
          let selectList = [];
          let selected = null;
          for (let option of config['SelectList']) {
            if (option['Selected']) selected = option['Val'];
            selectList.push({ key: option['Key'], val: option['Val'], selected: option['Selected'] });
          }
          settingField.configValue = selected;
          settingField.selectList = selectList;
        }

        if (config['CheckBoxList']) { // checkbox
          let checkboxList = [];
          let checked = [];
          for (let option of config['CheckBoxList']) {
            if (option['Checked']) checked.push(option['Val']);
            checkboxList.push({ key: option['Key'], val: option['Val'], checked: option['Checked'] });
          }
          settingField.configValue = checked;
          settingField.checkboxList = checkboxList;

        }
        settingConfig.settingConfigLists.push(settingField);
        if (config['RequiredConfirm'] === 1) {
          let settingFieldConfirm;
          settingFieldConfirm = JSON.parse(JSON.stringify(settingField));
          settingFieldConfirm.displayName = config['DisplayName'] + ' Confirm';
          settingFieldConfirm.fieldName = 'confirm_' + config['FieldName'];
          settingFieldConfirm.requiredConfirm = 0;
          settingConfig.settingConfigLists.push(settingFieldConfirm);
        }

      }
      settingConfigLists.push(settingConfig);
    }
    this.initDepend(settingConfigLists);
    return settingConfigLists;
  }


  static toSettingConfigParam(res) {
    let paramList = [];
    for (let field of res) {
      let configParam = {};
      let configValue = [];
      if (field.dataType === 'bool') {
        if (Array.isArray(field.configValue)) {
          configValue.push(field.configValue);
        } else {
          if (field.configValue === false) configValue.push('false');
          if (field.configValue === true) configValue.push('true');
        }
      } else if (field.dataType === 'int' && (field.configValue === null || field.configValue === '')) {
      } else configValue.push(field.configValue);
      configParam['ConfigValue'] = configValue.join(',').split(',');
      configParam['MerchantConfigFieldKey'] = field.merchantConfigFieldKey;
      paramList.push(configParam);
    }
    let obj = {};
    let params = paramList.reduce((cur, next) => {
      obj[next.MerchantConfigFieldKey] ? '' : obj[next.MerchantConfigFieldKey] = true && cur.push(next);
      return cur;
    }, []);
    return paramList;
  }

  static initDepend(settingConfigLists: Array<SettingConfig>) {
    let hidden = [];
    for (let settingConfig of settingConfigLists) {
      let group = settingConfig.settingConfigLists;
      for (let settingField of settingConfig.settingConfigLists) {
        let display = this.findDepend(settingField, group);
        settingField.display = display;
        if (!display) hidden.push(settingField.fieldName);
      }
    }
    return hidden;
  }

  static findDepend(settingField: SettingField, group: Array<SettingField>) {
    if (!settingField.dependFieldKey) return true;
    for (let obj of group) {
      if (obj.merchantConfigFieldKey === settingField.dependFieldKey) {
        if (obj.configValue === settingField.dependValue) {
          if (!obj.dependFieldKey) return true;
          else return this.findDepend(obj, group);
        } else return false;
      }
    }
    return true;
  }

  static dependChanged(settingField: SettingField, group: Array<SettingField>): Array<string> {
    let hidden = [];
    for (let obj of group) {
      if (obj.dependFieldKey === settingField.merchantConfigFieldKey) {
        let display = settingField.display && obj.dependValue === settingField.configValue;
        obj.display = display;
        if (!display) hidden.push(obj.fieldName);
        hidden = hidden.concat(this.dependChanged(obj, group));
      }
    }
    return hidden;
  }

  getSettingConfig(): Promise<any> {
    let response: Response<any> = new Response<any>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/SettingConfig/GetSettingConfig', {
        restful: {}
      }).subscribe(res => {
        response.data = SettingConfigService.toSettingConfigModel(res.SettingConfigList);
        resolve(response);
      }, error => reject(error));
    });
  }

  saveSettingConfig(SettingConfigLists): Promise<any> {
    let response: Response<any> = new Response<any>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/SettingConfig/SaveSettingConfig', {
        data: {
          SaveSettingFieldList: SettingConfigService.toSettingConfigParam(SettingConfigLists)
        }
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }
}

