import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhoneFormatDirective} from './phone-format.directive';
import {BackDirective} from './back.directive';
import {PasswordDirective} from './password.directive';
import {InputLimitDirective} from './input-limit.directive';
import {ValidateEqualDirective} from './validate-equal.directive';
import {DebounceSubmitDirective} from './debounce-submit.directive';
import {InputPriceDirective} from './input-price.directive';
import {InputIntegerDirective} from './input-integer.directive';
import { TooltipDirective } from './tooltip-directive';
import { TableHoverDirective } from './table-hover-content.directive';
import { MobileDirective } from './isMobile.directive';

@NgModule({
  declarations: [
    PhoneFormatDirective,
    BackDirective,
    PasswordDirective,
    InputLimitDirective,
    InputPriceDirective,
    ValidateEqualDirective,
    DebounceSubmitDirective,
    InputIntegerDirective,
    TooltipDirective,
    TableHoverDirective,
    MobileDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PhoneFormatDirective,
    BackDirective,
    PasswordDirective,
    InputLimitDirective,
    InputPriceDirective,
    ValidateEqualDirective,
    DebounceSubmitDirective,
    InputIntegerDirective,
    TooltipDirective,
    TableHoverDirective,
    MobileDirective
  ]
})
export class DirectivesModule {
}
