export class SettingConfig {
    groupName: string;
    open: boolean;
    settingConfigLists: Array<SettingField>;
    constructor(options: {
        groupName?: string;
        open?: string;
        settingConfigLists?: Array<SettingField>
    } = {}) {
        this.groupName = options.groupName || '';
        this.open = false;
        this.settingConfigLists = options.settingConfigLists || [];
    }
}

export class SettingField {
    configValue: any;
    dataType: string;
    description: string;
    displayName: string;
    fieldName: string;
    fieldType: string;
    maxLength: number;
    required: number;
    requiredConfirm: number;
    merchantConfigFieldKey: number;
    selectList: Array<any>;
    checkboxList: Array<any>;
    dependFieldKey: number;
    dependValue: boolean;
    regEx: string;
    errorMessage: string;
    display: boolean;
    isDisplay: boolean;
    constructor(options: {
        configValue?: any,
        dataType?: string,
        description?: string;
        displayName?: string;
        fieldName?: string;
        fieldType?: string;
        maxLength?: number;
        required?: number;
        requiredConfirm?: number;
        merchantConfigFieldKey?: number;
        selectList?: Array<any>;
        checkboxList?: Array<any>;
        dependFieldKey?: number;
        dependValue?: boolean;
        regEx?: string;
        errorMessage?: string;
        display?: boolean;
        isDisplay?: boolean;
    } = {}) {
        this.configValue = options.configValue;
        this.dataType = options.dataType || '';
        this.description = options.description || '';
        this.displayName = options.displayName || '';
        this.fieldName = options.fieldName || '';
        this.fieldType = options.fieldType || '';
        this.maxLength = options.maxLength || null;
        this.required = options.required || 0;
        this.requiredConfirm = options.requiredConfirm || 0;
        this.merchantConfigFieldKey = options.merchantConfigFieldKey || null;
        this.selectList = options.selectList || [];
        this.checkboxList = options.checkboxList || [];
        this.regEx = options.regEx || '';
        this.errorMessage = options.errorMessage || '';
        this.dependFieldKey = options.dependFieldKey || null;
        this.dependValue = options.dependValue || null;
        this.display = options.display || true;
        this.isDisplay = options.isDisplay || true;
    }
}
