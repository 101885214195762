import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[price]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPriceDirective),
      multi: true
    }
  ]
})

export class InputPriceDirective implements ControlValueAccessor {

  @HostListener('input', ['$event']) onChange = (_: any) => {
  };

  @HostListener('blur', ['$event']) onTouched = (_: any) => {
  };

  get element() {
    return this.elementRef.nativeElement;
  }

  constructor(private elementRef: ElementRef, private _renderer: Renderer2) {
  }

  writeValue(value: string): void {
    this._renderer.setProperty(this.element, 'value', this.formatPrice(value));
  }

  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this.element, 'disabled', isDisabled);
  }

  registerOnChange(fn: any): void {
    this.onChange = (event) => {
      let data = event['data'];
      let value = event.target['value'];
      
      const price = this.formatPrice(value, data);
      this._renderer.setProperty(this.element, 'value', price);
      fn(price);
    };
  }

  registerOnTouched(fn: any): void {
    // this.onTouched = (event) => {
    //   if(event.target['value']){
    //     let amount = Number(event.target['value']);
    //     // let amount = Number(event.target['value']) || 0;
    //     let price = amount.toFixed(2);
    //     this._renderer.setProperty(this.element, 'value', price);
    //     fn(price);
    //   }
    // };
  }

  formatPrice(price: string, data?: string) {
    if (!price) return '';
    let formatPrice: string = price.toString().replace(/[^\d.]/g, '');

    let arr = formatPrice.split('.');
    let res = arr.join('').replace(/^0*/g, '');

    if (data !== '.') {
      let lst = arr[arr.length - 1];
      let num = (arr.length !== 1 && lst.length === 1 && data) ? 1 : 2;
      while (res.length < num + 1) {
        res = '0' + res;
      }
      res = res.substring(0, res.length - num) + '.' + res.substring(res.length - num, res.length);
    } else if (data === '.') {
      res += (!res.length) ? '0.' : '.';
    }
    return res;
  }

}
