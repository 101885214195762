import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Response } from "../models/response";  

@Injectable({
  providedIn: "root",
})
export class CallbackService {
  constructor(
    private http: HttpService,
  ) {}

  exchangeToken(code): Promise<Response<any>> {
    let response: Response<any> =  new Response<any>();
    return new Promise((resolve, reject) => {
      this.http.get('/railwayAuth0/exchange_token', {
        restful: {code}
      }
      ).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error =>  reject(error));
    });
  }

  getUserInfo(): Promise<Response<any>> {
    let response: Response<any> =  new Response<any>();
    return new Promise((resolve, reject) => {
      // https://portal-server-dev.up.railway.app/v1/users/me
      this.http.get('/railwayPortal/users/me',
      ).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error =>  reject(error));
    });
  }
}
