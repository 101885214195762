import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ValidatorsRules } from './validators-rules';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() {
  }

  static password(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.password.reg);
    if (control.value && !reg.test(control.value)) {
      return { password: true };
    }
    return null;
  }

  static passcode_4(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.passcode_4.reg);
    if (control.value && !reg.test(control.value)) {
      return { passcode_4: true };
    }
    return null;
  }

  static passcode_6(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.passcode_6.reg);
    if (control.value && !reg.test(control.value)) {
      return { passcode_6: true };
    }
    return null;
  }

  static compare(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {

      let control = formGroup.controls[controlName];
      let matchingControl = formGroup.controls[matchingControlName];
      // if (matchingControl.errors && !matchingControl.errors.ComparePassword) return;
      if (control.value !== matchingControl.value && controlName === 'newPwd') {
        matchingControl.setErrors({ comparePassword: true });
      } else if (control.value !== matchingControl.value && controlName === 'newPcd') {
        matchingControl.setErrors({ comparePasscode: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  static compareInput(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      let control = formGroup.controls[controlName];
      let matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.compare) return;
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ compare: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  static specialValidator(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.specialValidator.reg);
    if (control.value && !reg.test(control.value)) {
      return { specialValidator: true };
    }
    return null;
  }

  static legal(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.legal.reg);
    if (control.value && !reg.test(control.value)) {
      return { legal: true };
    }
    return null;
  }

  static Integer(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.Integer.reg);
    if (control.value && !reg.test(control.value)) {
      return { Integer: true };
    }
    return null;
  }
  
  static PositiveInteger(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.PositiveInteger.reg);
    if (control.value && !reg.test(control.value) || control.value == 0) {
      return { PositiveInteger: true };
    }
    return null;
  }

  static email(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.email.reg);
    if (control.value && !reg.test(control.value)) {
      return { email: true };
    }
    return null;
  }

  static validateUrl(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.url.reg);
    if (control.value && !reg.test(control.value)) {
      return { url: true };
    }
    return null;
  }
  
  static regExValidator(regx: string,message: string): { [key: string]: any } | null {
    return (control: AbstractControl): { [key: string]: any } => {
      let reg = new RegExp(regx);
      let error = null;
      if (regx && !reg.test(control.value)) {
        return { illegal : message };
      }
      return null;
    };
  }

  static regHasSpecialChar(control: AbstractControl): { [key: string]: any } | null {
    let reg = new RegExp(ValidatorsRules.specialChar.reg);
    if (control.value && !reg.test(control.value)) {
      return { regHasSpecialChar: true };
    }
    return null;
  }
}
