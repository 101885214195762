import {Component, ElementRef, Input, OnInit, TemplateRef} from '@angular/core';
import {User} from 'src/app/models/user';
import {ProfileService} from 'src/app/services/profile.service';
import {DialogService} from 'src/app/utils/dialog.service';
import {LoadingService} from 'src/app/utils/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {ValidatorsService} from 'src/app/validtors/validators.service';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {AuthService} from 'src/app/services/auth.service';
import {Constant} from 'src/app/constants/constant';
import {MerchantService} from 'src/app/services/merchant.service';
import {Pagination} from 'src/app/models/pagination';
import {Merchant} from 'src/app/models/merchant';
import {Modal} from 'src/app/utils/modal.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileComponent implements OnInit {

  @Input() profileModal: Modal
  userEdit: boolean = false;
  isChangeMerchant: boolean = false;
  isChangeEmail: boolean = false;
  isChangePwd: boolean = false;
  isChangeCode: boolean = false;
  hasBeenSetCode: boolean = false;

  userForm: FormGroup;
  passwordForm: FormGroup;
  passcodeForm: FormGroup;

  submitted = false;
  password_submitted = false;
  passcode_submitted = false;
  countryList = Constant.COUNTRY_LIST;
  profile: User = new User();

  merchants: Array<{ key: string, val: string }> = [];
  orgPrimaryMerchantKey: string;
  primaryMerchantKey: string;

  passcodeLength: number = 6;
  merchantUserKey: string;
  error: any;
  pagination: Pagination<Merchant> = new Pagination<Merchant>();
  password: string;
  modal: Modal;

  constructor(private profileService: ProfileService, private translate: TranslateService, private authService: AuthService,
              private dialog: DialogService, private formBuilder: FormBuilder, private loading: LoadingService,
              private merchantService: MerchantService, private el: ElementRef) {
  }

  ngOnInit() {
    this.initForm();
    setTimeout(() => {
      this.getProfile();
      this.merchantUserKey = this.authService.getAuth().merchantUserKey;
    })
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      nickname: ['', [Validators.required, Validators.maxLength(60)]],
      given_name: ['', [Validators.required, Validators.maxLength(32)]],
      family_name: ['', [Validators.required, Validators.maxLength(32)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
      /*phone_number: ['', [Validators.required, Validators.maxLength(25)]],
      mobile: ['', Validators.maxLength(25)],
      streetAddress1: ['', [Validators.maxLength(256)]],
      streetAddress2: ['', [Validators.maxLength(256)]],
      city: ['', [Validators.maxLength(32)]],
      state: ['', [Validators.maxLength(32)]],
      country: [this.countryList[0], Validators.maxLength(2)],
      zip: ['', [Validators.maxLength(9), Validators.minLength(5)]]*/
    });

    this.userForm.disable();

    this.passwordForm = this.formBuilder.group({
          oldPwd: ['', [Validators.required, Validators.maxLength(256)]],
          newPwd: ['', [Validators.required, ValidatorsService.password, Validators.maxLength(256)]],
          confirmPwd: ['', [Validators.required, ValidatorsService.password, Validators.maxLength(256)]],
        }, {
          validator: ValidatorsService.compare('newPwd', 'confirmPwd')
        }
    );

    this.passcodeForm = this.formBuilder.group({
          oldPcd: ['', Validators.maxLength(6)],
          newPcd: [''],
          confirmPcd: [''],
        }, {
          validator: ValidatorsService.compare('newPcd', 'confirmPcd')
        }
    );
  }

  get f() {
    return this.userForm.controls;
  }

  get pwd() {
    return this.passwordForm.controls;
  }

  get pcd() {
    return this.passcodeForm.controls;
  }

  getProfile() {
    this.loading.show();
    this.profileService.getProfile().then((res) => {
      console.log(res.data)
      this.profile = res.data;
      this.userForm.patchValue(res.data);
      if (res.data.authenticationFlag === 1) {
        this.el.nativeElement.querySelector('.sp-switch').checked = true;
      }
    }).catch(res => this.dialog.error(res)).finally(() => this.loading.hide());
  }

  editInfo() {
    this.userEdit = true;
    this.userForm.enable();
    // this.f.email.disable();
  }

  cancelInfo() {
    this.userEdit = false;
    this.userForm.patchValue(this.profile);
    this.submitted = false;
    this.userForm.disable();
  }

  onSubmit() {
    this.submitted = true;
    if (this.userForm.invalid) return;
    this.loading.show();
    this.profileService.updateProfile(this.userForm.getRawValue()).then((res) => {
      this.dialog.createResDialog('success', 'PROFILE.CHANGE_PROFILE_SUCCESS', 2000);
      this.profile = this.userForm.getRawValue();
      this.cancelInfo();
      
      this.profileService.profileChanged(this.profile);
    }).catch(e => {
      const msg = e.httpError && e.httpError.error.error_msg || 'Fail to Change Profile'
      this.dialog.createResDialog('fail', msg);
    }
      ).finally(() => this.loading.hide());
  }

  async changePwd() {
    try{
      this.loading.show();
      await this.profileService.resetPassword({email: this.profile.email})
      this.dialog.createResDialog('success', 'We have just sent you an email to reset your password.');
    } catch(e) {
      const msg = e.httpError && e.httpError.error.error_msg || 'Fail to reset password.'
      this.dialog.createResDialog('fail', msg);
    } finally {
      this.loading.hide();
    }
  }

  cancelPwd() {
    this.isChangePwd = false;
    this.passwordForm.patchValue({'oldPwd': '', 'newPwd': '', 'confirmPwd': ''});
    this.password_submitted = false;
  }

  submitPwd() {
    this.password_submitted = true;
    if (this.passwordForm.invalid) return;
    this.loading.show();
    this.profileService.changePassword(this.passwordForm.getRawValue()).then((res) => {
      this.dialog.createResDialog('success', 'PROFILE.CHANGE_PASSWORD_SUCCESS');
      this.cancelPwd();
    }).catch(e => {
      const msg = e.httpError && e.httpError.error.error_msg || 'Fail to Change Profile'
      this.dialog.createResDialog('fail', msg);
    }).finally(() => this.loading.hide());

  }

  changeCode() {
    this.profileService.checkPasscode().then((res) => {
      this.isChangeCode = true;
      this.hasBeenSetCode = res.data.hasBeenSetCode;
      if (!this.hasBeenSetCode) this.passcodeForm.get('oldPcd').clearValidators();
      else this.passcodeForm.get('oldPcd').setValidators([Validators.required, Validators.maxLength(6)]);
      // this.passcodeLength = res.data.passcodeLength;
      let lengthValid = this.passcodeLength === 6 ? ValidatorsService.passcode_6 : ValidatorsService.passcode_4;
      this.passcodeForm.get('newPcd').clearValidators();
      this.passcodeForm.get('confirmPcd').clearValidators();
      this.passcodeForm.get('newPcd').setValidators([Validators.required, lengthValid]);
      this.passcodeForm.get('confirmPcd').setValidators([Validators.required, lengthValid]);
    }).catch(error => this.dialog.error(error));
  }

  cancelCode() {
    this.isChangeCode = false;
    this.passcodeForm.patchValue({'oldPcd': '', 'newPcd': '', 'confirmPcd': ''});
    this.passcode_submitted = false;
  }

  submitCode() {
    this.passcode_submitted = true;
    if (this.passcodeForm.invalid) return;
    if (this.hasBeenSetCode) this.changePasscode();
    else this.setPasscode();
  }

  changePasscode() {
    this.loading.show();
    this.profileService.changePasscode(this.passcodeForm.getRawValue()).then((res) => {
      // this.dialog.success('PROFILE.CHANGE_PASSCODE_SUCCESS');
      this.dialog.success('PROFILE.CHANGE_PASSCODE_SUCCESS');
      this.cancelCode();
    }).catch(res => this.dialog.error(res)).finally(() => this.loading.hide());
  }

  setPasscode() {
    this.loading.show();
    this.profileService.setPasscode(this.passcodeForm.getRawValue()).then((res) => {
      this.dialog.success('PROFILE.SET_PASSCODE_SUCCESS');
      this.cancelCode();
    }).catch(res => this.dialog.error(res)).finally(() => this.loading.hide());
  }

  changeMerchant() {
    this.isChangeMerchant = true;
  }

  submitMerchant() {
    this.loading.show();
    this.merchantService.setPrimaryMerchant(this.primaryMerchantKey).then(() => {
      this.isChangeMerchant = false;
      this.orgPrimaryMerchantKey = this.primaryMerchantKey;
      this.dialog.success();
    }).catch(error => {
      this.dialog.error_msg(error);
    }).finally(() => { 
      this.loading.hide();
    });
  }

  changeTwoFac(){ 
    this.modal.hide();
    this.loading.show();
    let flag = 0;
    if (this.el.nativeElement.querySelector('.sp-switch').checked === true)flag = 1; 
    
    this.merchantService.editTwoFactorAuth(flag, this.password).then(() => {
      this.password = '';
    }).catch(error => {
        this.dialog.error(error);
        this.password = '';
        this.el.nativeElement.querySelector('.sp-switch').checked = !flag;
      }).finally(() => this.loading.hide());
  }

  cancelMerchant() {
    this.isChangeMerchant = false;
    this.primaryMerchantKey = this.orgPrimaryMerchantKey;
  }

  changeEmail() {
    this.isChangeEmail = true;
  }

  successEmail(email) {
    this.profile.email = email;
    this.loading.show();
    this.profileService.updateProfile({email: email}).then((res) => {
      this.dialog.createResDialog('success', 'PROFILE.CHANGE_PROFILE_SUCCESS', 2000);
    }).catch(res => this.dialog.error(res)).finally(() =>{ 
      this.loading.hide();
      this.dialog.success();
      this.cancelEmail();
    });
  }

  cancelEmail() {
    this.isChangeEmail = false;
  }

  onChange(e): void {
    this.pagination.currPage = e;
  }

 psd = {
  showModal:(template: TemplateRef<any>) =>{
    this.modal = this.dialog.modal(template, 'Input your password');
    this.modal.show();
  },
  hideModal: ()=>{
    let flag = this.el.nativeElement.querySelector('.sp-switch').checked;
    this.el.nativeElement.querySelector('.sp-switch').checked = !flag;
    this.modal.hide();
  }
 }

 cancelEvent() {
  this.profileModal.hide()
 }
}
