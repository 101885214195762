export class Receipt {
    logo: string;
    headerDefaultFlag: boolean;
    header1: string;
    header2: string;
    header3: string;
    header4: string;
    header5: string;
    footerDefaultFlag: boolean;
    footer1: string;
    footer2: string;
    footer3: string;
    footer4: string;
    footer5: string;
    defaultHeader1: string;
    defaultHeader2: string;
    defaultHeader3: string;
    defaultHeader4: string;
    defaultHeader5: string;
    defaultFooter1: string;
    defaultFooter2: string;
    defaultFooter3: string;
    defaultFooter4: string;
    defaultFooter5: string;
}
