import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Item} from '../../models/Item';
import {Modal} from '../../utils/modal.service';
import {DialogService} from '../../utils/dialog.service';

@Component({
  selector: 'app-choose-modifier',
  templateUrl: './choose-modifier.component.html',
  styleUrls: ['./choose-modifier.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ChooseModifierComponent,
    multi: true
  }]
})

export class ChooseModifierComponent implements ControlValueAccessor, OnChanges {

  @Output() valueChange = new EventEmitter();
  @Input() modifiers: Array<Item> = [];

  modifierGuids: Array<string> = [];
  modal: Modal;

  disabled: boolean;
  onChangeListener; // 改变值回调
  onTouchedListener; // 交互回调

  constructor(private dialog: DialogService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  _valueChange(val) {
    this.modifierGuids = val;
    this.valueChange.emit(val);
    this.onChangeListener(val); // 告诉form，你的表单值改变成了payload
    this.onTouchedListener(); // 告诉form，你的表单有交互发生
  }

  writeValue(obj: any): void {
    this.modifierGuids = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn; // 保存这个函数
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn; // 保存这个函数
  }

  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }

  showItemModal(template: TemplateRef<any>) {
    this.modal = this.dialog.modal(template, '', 'modal-dialog-lg');
    this.modal.show();
  }

  getSelectItems(Modifiers) {
    let modifierGuids = this.modifierGuids;
    Modifiers.forEach((m) => {
      this.modifiers.push(new Item({guid: m.ModifierGuid, itemName: m.Name}));
      modifierGuids.push(m.ModifierGuid);
    });
    this._valueChange(modifierGuids);
  }

  moveModifier(item) {
    let index = this.modifiers.indexOf(item);
    if (index > -1) {
      this.modifiers.splice(index, 1);
    }
    let modifierGuids = this.modifierGuids;
    let indexId = modifierGuids.indexOf(item.guid);
    if (indexId > -1) {
      modifierGuids.splice(indexId, 1);
    }
    this._valueChange(modifierGuids);
  }
}
