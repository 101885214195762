export class User {
  merchantUserKey: string;
  userName: string;
  firstName: string;
  lastName: string;
  merchantName: string;
  email: string;
  phone: string;
  mobile: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  status: string;
  role: string;
  country: string;
  zip: string;

  password: string;
  passcode: string;

  image: string;
  lastLogon: string;

  registerFlag: boolean;
  authenticationFlag: number;
  isPrimaryUser: boolean;
  editFlag: boolean;

  constructor(options: {
    merchantUserKey?: string,
    userName?: string,
    firstName?: string,
    lastName?: string,
    merchantName?: string,
    email?: string,
    phone?: string,
    mobile?: string,
    streetAddress1?: string,
    streetAddress2?: string,
    city?: string,
    state?: string,
    status?: string,
    role?: string,
    country?: string,
    zip?: string,
    registerFlag?: boolean,
    authenticationFlag?: number,
    isPrimaryUser?: boolean,
    editFlag?: boolean
  } = {}) {
    this.merchantUserKey = options.merchantUserKey || '';
    this.userName = options.userName || '';
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.merchantName = options.merchantName || '';
    this.email = options.email || '';
    this.phone = options.phone || '';
    this.mobile = options.mobile || '';
    this.streetAddress1 = options.streetAddress1 || '';
    this.streetAddress2 = options.streetAddress2 || '';
    this.city = options.city || '';
    this.state = options.state || '';
    this.status = options.status || '';
    this.role = options.role || '';
    this.country = options.country || '';
    this.zip = options.zip || '';
    this.registerFlag = options.registerFlag || false;
    this.authenticationFlag = options.authenticationFlag || 0;
    this.isPrimaryUser = options.isPrimaryUser || false;
    this.editFlag = options.editFlag || true;
  }
}
