import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Response} from '../models/response';
import {User} from '../models/user';
import {Observable, Subject} from 'rxjs';
import {EncryptService} from '../utils/encrypt.service';
import {Constant} from '../constants/constant';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpService, private authService: AuthService) {
  }

  private profileSubject = new Subject<any>();

  getProfileChanges(): Observable<any> {
    return this.profileSubject.asObservable();
  }

  static toUserModel(res): User {

    let profile: User = new User();
    profile.email = res['Email'];
    profile.userName = res['UserName'];
    profile.firstName = res['FirstName'];
    profile.lastName = res['LastName'];
    profile.role = res['RoleName'];
    profile.phone = res['Phone'];
    profile.mobile = res['Mobile'];
    profile.streetAddress1 = res['StreetAddress1'];
    profile.streetAddress2 = res['StreetAddress2'];
    profile.city = res['City'];
    profile.state = res['State'];
    profile.country = res['Country'];
    profile.zip = res['ZIPCode'];
    profile.authenticationFlag = res['AuthenticationFlag'];
    return profile;
  }

  get(): Observable<any> {
    return this.profileSubject.asObservable();
  }

  profileChanged(profile: any) {
    this.authService.setProfile(profile);
    this.profileSubject.next(profile);
  }

  getProfile(): Promise<Response<any>> {
    let response: Response<any> = new Response<any>();
    return new Promise((resolve, reject) => {
      this.http.get('/railwayPortal/users/me').subscribe(res => {
        // response.data = ProfileService.toUserModel(res);
        response.data = res
        resolve(response);
      }, error => reject(error));
    });
  }

  updateProfile(param): Promise<Response<User>> {
    let response: Response<User> = new Response<User>();
    return new Promise((resolve, reject) => {
      this.http.patch('/railwayPortal/users/me', param).subscribe(res => {
        response.data = res
        resolve(response);
      }, error => reject(error));
    });
  }

  resetPassword(param): Promise<Response<User>> {
    let response: Response<User> = new Response<User>();
    return new Promise((resolve, reject) => {
      // https://auth-server-dev.up.railway.app/reset_password
      this.http.post('/railwayAuth0/reset_password?email=' + param.email).subscribe(res => {
        response.data = res
        resolve(response);
      }, error => reject(error));
    });
  }

  changeProfile(params): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Profile/ChangeUserProfile', {
        data: {
          'Email': params.email,
          'FirstName': params.firstName,
          'LastName': params.lastName,
          'UserName': params.userName,
          'Phone': params.phone,
          'Mobile': params.mobile,
          'StreetAddress1': params.streetAddress1,
          'StreetAddress2': params.streetAddress2,
          'City': params.city,
          'State': params.state,
          'Country': params.country,
          'ZIPCode': params.zip
        }
      }).subscribe(res => {
        response.data = res['Email'];
        resolve(response);
      }, error => reject(error));
    });
  }

  getSalt(): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Security/GetForeEndSalt').subscribe(res => {
        response.data = res['ForeEndSalt'];
        resolve(response);
      }, error => reject(error));
    });
  }

  changePassword(data: { oldPwd, newPwd, confirmPwd }): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.getSalt().then((salt) => {
        this.http.post('/Merchant/Security/ChangePassword', {
          data: {
            'OldPassword': EncryptService.salt(data.oldPwd, salt.data),
            'NewPassword': EncryptService.salt(data.newPwd, salt.data),
            'ConfirmNewPassword': EncryptService.salt(data.confirmPwd, salt.data)
          }
        }).subscribe(res => {
          response.data = true;
          resolve(response);
        }, error => reject(error));
      }).catch(error => reject(error));
    });
  }

  checkPasscode(): Promise<Response<{ hasBeenSetCode: boolean, passcodeLength: number }>> {
    let response: Response<{ hasBeenSetCode: boolean, passcodeLength: number }>
        = new Response<{ hasBeenSetCode: boolean, passcodeLength: number }>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Security/CheckPasscode').subscribe(res => {
        response.data = {
          hasBeenSetCode: (res['CheckResultCode'] === Constant.ACTIVE_KEY),
          passcodeLength: res['MerchantPasscodeLength'] ? parseInt(res['MerchantPasscodeLength'], 10) : Constant.DEFAULT_PASSCODE_LENGTH,
        };
        resolve(response);
      }, error => reject(error));
    });
  }

  changePasscode(data: { oldPcd, newPcd, confirmPcd }): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.getSalt().then((salt) => {
        this.http.post('/Merchant/Security/ChangePasscode', {
          data: {
            'OldPasscode': EncryptService.salt(data.oldPcd, salt.data),
            'NewPasscode': EncryptService.salt(data.newPcd, salt.data),
            'ConfirmNewPasscode': EncryptService.salt(data.confirmPcd, salt.data)
          }
        }).subscribe(res => {
          response.data = true;
          resolve(response);
        }, error => reject(error));
      }).catch(error => reject(error));
    });
  }

  setPasscode(data: { newPcd, confirmPcd }): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.getSalt().then((salt) => {
        this.http.post('/Merchant/Security/SetPasscode', {
          data: {
            'NewPasscode': EncryptService.salt(data.newPcd, salt.data),
            'ConfirmPasscode': EncryptService.salt(data.confirmPcd, salt.data)
          }
        }).subscribe(res => {
          response.data = true;
          resolve(response);
        }, error => reject(error));
      }).catch(error => reject(error));
    });
  }

  sendVerificationCode(email: string, password: string): Promise<Response<any>> {
    let response: Response<string> = new Response<string>();
    let salt = this.authService.getSalt();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Security/SendVerificationCode', {data: {'Email': email,'Password': EncryptService.salt(password, salt)}}).subscribe(res => {
        resolve(response);
      }, error => reject(error));
    });
  }

  editLoginInUserEmail(verificationCode): Promise<Response<string>> {  // return newEmail
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Security/EditLoginInUserEmail', {
        data: {'VerificationCode': verificationCode}
      }).subscribe(res => {
        response.data = res['AfterEmail'];
        resolve(response);
      }, error => reject(error));
    });
  }

  editOtherUserEmail(merchantUserKey, email): Promise<Response<string>> {  // return newEmail
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Security/EditOtherUserEmail', {
        data: {'MerchantUserKey': merchantUserKey, 'AfterEmail': email}
      }).subscribe(res => {
        response.data = res['AfterEmail'];
        resolve(response);
      }, error => reject(error));
    });
  }

}
