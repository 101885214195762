export class Device {
  merchantName: string;
  tenderType: string;
  processorName: string;
  registerNum: string;
  terminalSN: Array<any>;

  constructor(options: {
    merchantName?: string,
    tenderType?: string,
    processorName?: string,
    registerNum?: string,
    terminalSN?: Array<any>
  } = {}) {
    this.merchantName = options.merchantName || '';
    this.tenderType = options.tenderType || '';
    this.processorName = options.processorName || '';
    this.registerNum = options.registerNum || '';
    this.terminalSN = options.terminalSN || [];
  }
}
