import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InitiateService} from './services/login.service';
import {CustomService} from './services/custom.service';
import {Title} from '@angular/platform-browser';
import {version} from 'package.json';
import { GeneralService } from './utils/general.service';
import { Subscribable, Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Argo Labs';
  showloading = true;

  constructor(translate: TranslateService, private initiateService: InitiateService, private TitleService: Title, private generalService: GeneralService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }

  isPC;
  isPcChange: Subscription;

  ngOnInit(): void {
    this.generalService.created();
    this.isPC = this.generalService.isPC();
    this.isPcChange = this.generalService.getPCAgentChanges().subscribe(isPC => {
      this.isPC = isPC;
      this.setIsPCStyle();
    });
    this.initStyle();
  }

  ngOnDestroy(): void {
      this.isPcChange.unsubscribe();
      this.generalService.destroyed();
  }

  initStyle() {
    this.getTitleInfo();
    this.appendStyle('default');
    CustomService.setUrlPrefix('default');
  }

  setIsPCStyle() {
    if (!this.isPC) {
      document.documentElement.style.fontSize = 16 + 'px';
      document.body.style.fontSize = 16 + 'px';
      document.body.classList.add('mobile');
    } else {
      document.documentElement.style.fontSize = 14 + 'px';
      document.body.style.fontSize = 14 + 'px';
      document.body.classList.remove('mobile');
    }
  }

  getTitleInfo() {
      this.TitleService.setTitle('Argo Labs');
  }

  appendStyle(path) {
    
    let appends = [
      {
        tag: 'link',
        href: 'assets/themes/{{path}}/logo-sm.png',
        rel: 'icon',
        type: 'image/x-icon'
      }, {
        tag: 'link',
        href: 'assets/themes/{{path}}/style.css?v=' + version,
        rel: 'stylesheet',
        type: 'text/css'
      }
    ];

    let head = document.getElementsByTagName('head')[0];
    let child = head.firstChild;

    Object.keys(appends).forEach((i) => {
      let el = appends[i];
      let link = document.createElement('link');
      link.href = el.href.replace('{{path}}', path);
      link.rel = el.rel;
      link.type = el.type;
      head.insertBefore(link, child);
    });

    let title = CustomService.title();
    if (title) {
      let titleStyle = document.createElement('style');
      titleStyle.innerHTML = `.up-theme-title:before{content: "${title} ";}`;
      head.insertBefore(titleStyle, child);
    }
    
    this.setIsPCStyle();
     
    setTimeout(() => {
      this.showloading = false;
    }, 500);
  }

}
