import {Component, OnInit} from '@angular/core';
import {InitiateService} from '../../services/login.service';
import {LoadingService} from '../../utils/loading.service';
import {DialogService} from '../../utils/dialog.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ValidatorsService} from '../../validtors/validators.service';
import {Response} from '../../models/response';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-initial',
  templateUrl: './initial.component.html',
  host: {class: 'up-login-container'},
  styleUrls: ['./initial.component.scss']
})
export class InitialComponent implements OnInit {

  email;
  newPwd: string;
  confirmPwd: string;
  step: number = 0;

  initialForm: FormGroup;
  submitted: boolean = false;

  constructor(private initiateService: InitiateService, private loading: LoadingService, private dialog: DialogService,
              private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.initForm();

    this.activatedRoute.queryParams.subscribe(queryParams => {
      let token;
      for (let item in queryParams) {
        if (item.toLocaleLowerCase() === 'token') {
          token = queryParams[item];
          break;
        }
      }

      if (token) {
        this.verify(token);
      } else {
        let res: Response<any> = new Response<any>();
        res.resultMsg = 'INITIAL.INVALID_TOKEN';
        let alert = this.dialog.error(res);
        alert.onHidden.subscribe(this.toLogin);
      }
    });
  }

  initial() {
    this.submitted = true;
    if (this.initialForm.invalid) return;

    this.loading.show();
    this.initiateService.initiatePassword(this.initialForm.getRawValue()).then((res) => {
      this.step++;
      this.loading.hide();
    }).catch((res) => {
      this.dialog.error(res);
      this.loading.hide();
    });

  }

  verify(token) {
    this.initForm();
    this.initiateService.verify(token).then((res) => {
      if (res.data.noticeBind) {
        let alert = this.dialog.alert(this.translate.get('INITIAL.HAS_SET_PASSWORD',{email:res.data.email})['value']);
        alert.onHidden.subscribe(this.toLogin);
      } else {
        this.updateToken(res.data.token);
        this.f.salt.setValue(res.data.foreEndSalt);
        this.f.email.setValue(res.data.email);
        this.f.email.disable();
      }
    }).catch((res) => {
      let alert = this.dialog.error(res);
      if (alert && ['999695', '999998'].indexOf(res.resultCode) !== -1) alert.onHidden.subscribe(this.toLogin);
      // if (alert && res.resultCode.indexOf(['999695', '999998']) !== -1) alert.onHidden.subscribe(this.toLogin);
    });
  }

  initForm() {
    this.initialForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
          newPwd: ['', [Validators.required, ValidatorsService.password]],
          confirmPwd: ['', Validators.required],
          token: [''],
          salt: ['']
        },
        {
          validator: ValidatorsService.compare('newPwd', 'confirmPwd')
        }
    );
  }

  get f() {
    return this.initialForm.controls;
  }

  updateToken = (token) => {
    if (token) this.f.token.setValue(token);
  };

  toLogin = () => {
    this.router.navigate(['/login']);
  }
}
