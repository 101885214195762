import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: 'input[type=password]'
})
export class PasswordDirective {
  type: string;
  i: any;
  eyeClose: boolean;

  constructor(private _renderer: Renderer2, private el: ElementRef) {

  }
  ngAfterViewInit(){
    this.type = this.el.nativeElement.getAttribute('type');
    let parent = this._renderer.parentNode(this.el.nativeElement);
    this._renderer.appendChild(parent, this.create(this.el.nativeElement));
  }
  create(target) {
    let readonly = target.readOnly
    let _renderer = this._renderer;
    let i = _renderer.createElement('i');
    if(!readonly){
      if (this.type === 'password') {
        _renderer.addClass(i, 'sp-icon-eye_close');
        this.eyeClose = true;
      } else {
        _renderer.addClass(i, 'sp-icon-eye');
        this.eyeClose = false;
      }
    }
    _renderer.addClass(i, 'sp-icon-eye_common');
    _renderer.listen(i, 'click', () => {
      this.toggle();
    });
    let div = _renderer.createElement('div');
    _renderer.addClass(div, 'input-group');
    _renderer.addClass(div, 'password-input-group');
    _renderer.appendChild(div, target);
    _renderer.appendChild(div, i);

    this.i = i;
    return div;
  }

  toggle = () => {
    let _renderer = this._renderer;
    let el = this.el;
    let i = this.i;

    if (this.eyeClose) {
      this.eyeClose = false;
      _renderer.setProperty(el.nativeElement, 'type', 'text');
      _renderer.addClass(i, 'sp-icon-eye');
      _renderer.removeClass(i, 'sp-icon-eye_close');

    } else {
      this.eyeClose = true;
      _renderer.setProperty(el.nativeElement, 'type', 'password');
      _renderer.removeClass(i, 'sp-icon-eye');
      _renderer.addClass(i, 'sp-icon-eye_close');
    }

  };

}

