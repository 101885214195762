import {OrderLine} from './orderLine';

export class Order {
  createdDate: string;
  orderGuid: string;
  ecrrefNum: string;
  totalAmt: string;
  subTotal: string;
  taxAmt: string;
  discountAmt: string;
  tipAmt: string;
  surchargeAmt: string;
  orderName: string;
  transType: number;
  originalOrderGuid: string;
  orderStatus: string;
  paymentStatus: string;
  refundedAmt: string;
  nonCashAmt: string;
  deliveryAmt: string;
  specialInstructions: string;
  createdBy: string;
  customerInformation: string;
  orderLines: Array<OrderLine>;
  orderTotalTaxes: string;

  constructor(options: {
    createdDate?: string,
    orderGuid?: string,
    ecrrefNum?: string,
    totalAmt?: string,
    subTotal?: string,
    taxAmt?: string,
    discountAmt?: string,
    tipAmt?: string,
    surchargeAmt?: string,
    orderName?: string,
    transType?: number,
    originalOrderGuid?: string,
    orderStatus?: string,
    paymentStatus?: string,
    refundedAmt?: string,
    nonCashAmt?: string,
    deliveryAmt?: string,
    specialInstructions?: string,
    createdBy?: string,
    customerInformation?: string,
    orderLines?: Array<OrderLine>
  } = {}) {
    this.createdDate = options.createdDate || '';
    this.orderGuid = options.orderGuid || '';
    this.ecrrefNum = options.ecrrefNum || '';
    this.totalAmt = options.totalAmt || '';
    this.subTotal = options.subTotal || '';
    this.taxAmt = options.taxAmt || '';
    this.discountAmt = options.discountAmt || '';
    this.tipAmt = options.tipAmt || '';
    this.surchargeAmt = options.surchargeAmt || '';
    this.orderName = options.orderName || '';
    this.transType = options.transType;
    this.originalOrderGuid = options.originalOrderGuid || '';
    this.orderStatus = options.orderStatus || '';
    this.paymentStatus = options.paymentStatus || '';
    this.refundedAmt = options.refundedAmt || '';
    this.nonCashAmt = options.nonCashAmt || '';
    this.deliveryAmt = options.deliveryAmt || '';
    this.specialInstructions = options.specialInstructions || '';
    this.createdBy = options.createdBy || '';
    this.customerInformation = options.customerInformation || '';
    this.orderLines = options.orderLines || undefined;
  }
}
