import {Injectable} from '@angular/core';                                   // 没什么用。
import {environment} from '../../environments/environment';                 // 用处不大。就是拿到 baseUrl
import {Observable, throwError} from 'rxjs';                                // Observable 几乎是用作ts返回值类型，throwError 没什么用
import {catchError, map} from 'rxjs/operators';                             // map 就是用来判断是不是 000000，不是就抛异常
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';   // ! HttpHeaders 在这里没用（在哪里用到了？），HttpClient 是核心，HttpParams 在 get 中使用
import {Constant} from '../constants/constant';                             // 用处不大。在 extractData 中用到， 检查是不是 000000
import {Response as ResponseModel} from '../models/response';               // 用处不大。在 handleError 中用到
import {HttpParameterCodec} from '@angular/common/http';


// 请求类型
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  /**
   *  GET请求处理（一般用于获取数据）
   * @param url 后台接口api 例如：/api/test/6
   * @param param {}
   */
  public get(url: string, param = {}): Observable<any> {
    let params = new HttpParams({fromObject: param['restful'], encoder: new CustomHttpParamEncoder()});
    return this.http.get(`${this.baseUrl}${url}`, { params }).pipe(
      // 只要 ResultCode 不是 000000，就抛异常
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  /**
   * POST请求处理（一般用于保存数据）
   * @param url 后台接口api
   * @param param {restful,data}
   */
  public post(url: string, param = {}, baseUrl: string = this.baseUrl): Observable<any> {
    return this.http.post(`${this.baseUrl}${url}`, param['data'], {
      // 可以使用params选项来设置查询参数，这可以是一个HttpParams对象或一个普通的JavaScript对象。
      params: param['restful'],
      // 可以通过headers选项来设置请求头，这可以是一个HttpHeaders对象或一个普通的JavaScript对象。
      // 如果没有指定请求头，则默认使用'Content-Type': 'application/json'。
      headers: param['headers']
    }).pipe(
      // 只要 ResultCode 不是 000000，就抛异常
      map(this.extractData),
      catchError(this.handleError)
    );
  }
  /**
   * PUT请求处理（一般用于更新数据）
   * （目前没用用到过）
   * @param url 后台接口api 例如：/api/test/6
   * @param data 参数
   */
  public put(url: string, data = {}): Observable<any> {
    return this.http.put(`${this.baseUrl}${url}`, data).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public patch(url: string, data = {}): Observable<any> {
    return this.http.patch(`${this.baseUrl}${url}`, data).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  /**
   * DELETE请求处理（一般用于删除数据）
   * （目前没用用到过）
   * @param url 后台接口api 例如：/api/test/6
   */
  public delete(url: string, data = {}): Observable<any> {
    return this.http.delete(`${this.baseUrl}${url}`, data).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public getFile(url: string, param = {}, type = ''): Observable<any> {
    let params = new HttpParams({fromObject: param['restful'], encoder: new CustomHttpParamEncoder()});
    return this.http.get(`${this.baseUrl}${url}`, {
      params: params,
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map((res) => this.extractFileData(res, type)),
      catchError(this.handleFileError),
      catchError(this.handleError)
    );
  }

  public getFileUrl(url: string, param = {}, type = ''): Observable<any> {
    let params = new HttpParams({fromObject: param['restful'], encoder: new CustomHttpParamEncoder()});
    return this.http.get(`${this.baseUrl}${url}`, {
      params: params,
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map((res) => this.extractFileURL(res, type)),
      catchError(this.handleFileError),
      catchError(this.handleError)
    );
  }

  /**
   *  提取数据
   *  本质上就是返回原 res，但是如果不是 000000或者0000 就抛异常
   * @param res 返回结果
   */
  private extractData(res) { // : Response
    return res || {};
  }

  private extractFileData(res, type) {
    if (res.body.type === 'application/json') throw res.body;
    const contentDisposition = res.headers.get('Content-Disposition');
    let fileName, fileURL;
    if (contentDisposition) fileName = contentDisposition.split(';')[1].split('filename=')[1].replace(/"/g, '');
    if (!fileName) fileName = res.url.split('/').pop();
    if(type) {
      const audioBlob = new Blob([res.body], {type: type})
      fileURL = URL.createObjectURL(audioBlob);
    } else {
      fileURL = URL.createObjectURL(res.body);
    }
    let a = document.createElement('a');
    a.setAttribute('href', fileURL);
    a.setAttribute('download', fileName);
    a.click();
    return res;
  }


  private extractFileURL(res, type) {
    if (res.body.type === 'application/json') throw res.body;
    let fileURL
    if(type) {
      const audioBlob = new Blob([res.body], {type: type})
      fileURL = URL.createObjectURL(audioBlob);
    } else {
      fileURL = URL.createObjectURL(res.body);
    }
    return fileURL
  }

  /**
   * 错误消息类
   * @param error
   */
  private handleError(error) { // : HttpErrorResponse
    let response: ResponseModel<any> = new ResponseModel<any>();
    if (error instanceof ResponseModel) {
      response = error;
    } else if (error['ResultCode']|| error['resultCode']) {
      response.resultCode = error['ResultCode'] || error['resultCode'];
      response.resultMsg = error['ResultMsg'] ||  error['resultMsg'];
      response.metadata = error;
    }  else if (error['error_code']|| error['error_msg']) {
      response.resultCode = error['error_code'];
      response.resultMsg = error['error_msg'];
      response.metadata = error;
    } else {
      response.httpError = error;
    }
    return throwError(response);
  }

  public handleFileError(file): Observable<any> {
    return new Observable(observer => {
      if (file.type === 'application/json') {
        const reader = new FileReader();
        reader.onload = res => {
          const data = JSON.parse(res.target['result']); // 解析成json对象
          observer.error(data);
        }; // 成功回调
        reader.onerror = err => {
          observer.error(err);
        }; // 失败回调
        reader.readAsText(new Blob([file]), 'utf-8'); // 按照utf-8编码解析
      } else if(file.error.type === 'application/json') {
        const reader = new FileReader();
        reader.onload = res => {
          const data = JSON.parse(res.target['result'] as string); // 解析成json对象
          observer.error(data);
        }; // 成功回调
        reader.onerror = err => {
          observer.error(err);
        }; // 失败回调
        reader.readAsText(file.error, 'utf-8'); // 按照utf-8编码解析
      }else {
        observer.error(file);
      }
    });
  }
}


export class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return this.standardEncoding(key);
  }

  encodeValue(value: string): string {
    return this.standardEncoding(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  standardEncoding(v) {
    return encodeURIComponent(v)
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/gi, '$')
        .replace(/%2C/gi, ',')
        .replace(/%3B/gi, ';')
        .replace(/%09/gi, '%20')
        // .replace(/%2B/gi, '+')
        .replace(/%3D/gi, '=')
        .replace(/%3F/gi, '?')
        .replace(/%2F/gi, '/');
  }
}
