import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() {
  }

  static getDateString(date: Date) {
    let day = date.getDate(), mon = date.getMonth() + 1, year = date.getFullYear();
    let d = (day < 10 ? '0' : '') + day;
    let m = (mon < 10 ? '0' : '') + mon;
    return `${m}/${d}/${year}`;
  }

  static getTimeString(date: Date) {
    let h = date.getHours(), m = date.getMinutes(), s = date.getSeconds();
    let hh = (h < 10 ? '0' : '') + h;
    let mm = (m < 10 ? '0' : '') + m;
    let ss = (s < 10 ? '0' : '') + s;
    return `${hh}:${mm}:${ss}`;
  }

  static getDateAndTimeString(date: Date) {
    if (!date) return '';
    return `${DateService.getDateString(date)}  ${DateService.getTimeString(date)}`;
  }

  static getDateAndTimeStringUS(dateString: string, returnYear: boolean = true) {
    if (!dateString) return '';
    if (isNaN(new Date(dateString).getTime())) return dateString;
    const date = this.utcToLocal(dateString.substring(0, 19))

    // get the month abbreviation
    const monthAbbreviation = date.toLocaleString('en-US', { month: 'short' });
    const amPm = date.getHours() >= 12 ? 'PM' : 'AM';

    const d = date.getDate()
    const h = (date.getHours() % 12 || 12);
    const m = date.getMinutes();
    let day = (d < 10 ? '0' : '') + d;
    const hours = (h < 10 ? '0' : '') + h;
    const minutes = (m < 10 ? '0' : '') + m;

    if(!returnYear) {
      return `${monthAbbreviation} ${day} - ${hours}:${minutes} ${amPm}`;
    }
    
    return `${monthAbbreviation} ${day} ${date.getFullYear()} - ${hours}:${minutes} ${amPm}`;
  }

  static beginDateString(date: Date) {
    let day = date.getDate(), mon = date.getMonth() + 1, year = date.getFullYear();
    return `${year}-${mon}-${day}T00:00:00`;
  }

  static endDateString(date: Date) {
    let day = date.getDate(), mon = date.getMonth() + 1, year = date.getFullYear();
    return `${year}-${mon}-${day}T23:59:59`;
  }

  static DateoffsetString(date: Date, end?: boolean) {
    let t;
    if(end){
      t = `23:59:59`;
    }else{
      t = `00:00:00`;
    };
    let day = date.getDate(), mon = date.getMonth() + 1, year = date.getFullYear();
    let times =`${year}/${mon}/${day} ${t}`;
    let offset = new Date(times).getTimezoneOffset();
    let offsetnum = offset;
    if(offset < 0){
      offsetnum = -offsetnum
    }
    let h = offsetnum/60;
    let m = offsetnum%60;
    let hh = (h < 10 ? '0' : '') + h;
    let mm = (m < 10 ? '0' : '') + m;
  
    let time = `${year}-${mon}-${day}T${t} +${hh}:${mm}`
    if(offset > 0){
      time = `${year}-${mon}-${day}T${t} -${hh}:${mm}`
    }
    return time;
  }

  static daysAgo(endDate: Date, days: number): Date {
    let beginDate = new Date(endDate);
    beginDate.setDate(endDate.getDate() - days);
    return beginDate;
  }

  static localToUTC(localDate: string) {
    return new Date(localDate).toISOString().substring(0, 23);
  }

  static timeStringToDate(timeString) {
    if(timeString) {
      if(DateService.isValidTimeString(timeString)) {
        const [hours, minutes] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0); 
        date.setMilliseconds(0); 
        return date;
      } else return ''
    }
  }

  static DateToTimeString(date:string) {
    if(date) {
      const newDate = new Date(date);
      let h = newDate.getHours(), m = newDate.getMinutes();
      let hh = (h < 10 ? '0' : '') + h;
      let mm = (m < 10 ? '0' : '') + m;
      return `${hh}:${mm}`;
    }
  }


  static searchDateToUTC(localDate: Date, isEnd = false) {
    localDate.setHours(0, 0, 0, 0);
    if(isEnd) {
      const copyEndDate = new Date(localDate.getTime())
      const endDate =  new Date(copyEndDate.setDate(copyEndDate.getDate() + 1))
      return endDate.toISOString();
    } else {
      return localDate.toISOString();
    }
  }

  static utcToLocal(utcDate: string) {
    if (!utcDate) return;
    let date = new Date(utcDate).getTime();
    let offset = new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(date - offset);
  }

  static utcToLocalSpe(utcDate: string) {
    if (!utcDate) return;
    let formatDate = utcDate.replace(/-/g,'/');
    let date = new Date(formatDate).getTime();
    let offset = new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(date - offset);
  }

  static getDateFormat(date: Date) {
    let day = date.getDate(), mon = date.getMonth() + 1, year = date.getFullYear();
    let d = (day < 10 ? '0' : '') + day;
    let m = (mon < 10 ? '0' : '') + mon;
    return `${year}-${m}-${d}`;
  }

  static getDateSearch(date: Date, type: string) {
    let day = date.getDate(), mon = date.getMonth() + 1, year = date.getFullYear();
    let d = (day < 10 ? '0' : '') + day;
    let m = (mon < 10 ? '0' : '') + mon;
    return type === 'start' ? `${year}-${m}-${d}T00:00:00Z`: `${year}-${m}-${d}T23:59:59Z`;
  }

  static getDurationString(duration: number | string) {
    const mins = Math.floor(Number(duration) / 60)
    let secs = Number(duration) % 60
    const formartMins = mins > 0 ? `${mins}mins ` : ''
    const formartSecs = secs > 0 ? `${secs}secs` : ''
    return formartMins + formartSecs

  }

  static isValidTimeString(timeString) {
    const regex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
    return regex.test(timeString);
  }
}
