import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InitiateService} from '../../services/login.service';
import {LoadingService} from '../../utils/loading.service';
import {DialogService} from '../../utils/dialog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Response} from '../../models/response';
import {ValidatorsService} from '../../validtors/validators.service';
import {ResetService} from '../../services/login.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  host: {class: 'up-login-container'},
  styleUrls: ['./reset.component.scss']
})

export class ResetComponent implements OnInit {

  resetToken: string;
  salt: string;
  resetType: string;

  step: number = 0;

  submitted: boolean = false;

  passwordForm: FormGroup;
  passcodeForm: FormGroup;

  passcodeLength: number = 6;

  constructor(private initiateService: InitiateService, private loading: LoadingService, private dialog: DialogService,
              private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router,
              private resetService: ResetService) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(queryParams => {
      let token;
      for (let item in queryParams) {
        if (item.toLocaleLowerCase() === 'token') {
          token = queryParams[item];
          break;
        }
      }

      if (!token) {
        let res: Response<any> = new Response<any>();
        res.resultMsg = 'INITIAL.INVALID_TOKEN';
        let alert = this.dialog.error(res);
        alert.onHidden.subscribe(this.toLogin);
        return;
      }
      this.verify(token);
    });
  }

  verify(token) {
    this.resetService.verify(token).then((res) => {
      let resetType = res.data.resetType;
      this.salt = res.data.foreEndSalt;
      this.resetToken = res.data.token;
      if (resetType === 'Password') this.initPasswordForm();
      else if (resetType === 'Passcode') {
        // this.passcodeLength = res.data.passcodeLength;
        this.initPasscodeForm(this.passcodeLength);
      }
      this.resetType = resetType;
    }).catch((res) => {
      let alert = this.dialog.error(res);
      // if (alert && res.resultCode.indexOf(['999695', '999998']) !== -1) alert.onHidden.subscribe(this.toLogin);
      if (alert && ['999695', '999998'].indexOf(res.resultCode) !== -1) alert.onHidden.subscribe(this.toLogin);
    });
  }

  initPasswordForm() {
    this.passwordForm = this.formBuilder.group({
          newPwd: ['', [Validators.required, ValidatorsService.password]],
          confirmPwd: ['', Validators.required]
        },
        {
          validator: ValidatorsService.compare('newPwd', 'confirmPwd')
        }
    );
  }

  initPasscodeForm(length) {
    let lengthValid = length === 6 ? ValidatorsService.passcode_6 : ValidatorsService.passcode_4;
    this.passcodeForm = this.formBuilder.group({
          newPcd: ['', [Validators.required, lengthValid]],
          confirmPcd: ['', [Validators.required, lengthValid]]
        },
        {
          validator: ValidatorsService.compare('newPcd', 'confirmPcd')
        }
    );
  }

  toLogin = () => {
    this.router.navigate(['/login']);
  };

  get pwd() {
    return this.passwordForm.controls;
  }

  get pcd() {
    return this.passcodeForm.controls;
  }

  setPassword() {
    this.submitted = true;
    if (this.passwordForm.invalid) return;
    this.loading.show();
    this.resetService.resetPassword(this.passwordForm.getRawValue(), this.resetToken, this.salt).then(() => {
      this.step++;
    }).catch(res => this.dialog.error(res))
        .finally(() => this.loading.hide());
  }


  setPasscode() {
    this.submitted = true;
    if (this.passcodeForm.invalid) return;
    this.loading.show();
    this.resetService.resetPasscode(this.passcodeForm.getRawValue(), this.resetToken, this.salt).then(() => {
      this.step++;
    }).catch(res => this.dialog.error(res))
        .finally(() => this.loading.hide());
  }


}
