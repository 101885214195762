export const ForbiddenAuthorityMap = {
  boa: ['transaction_view_void'] // 'transaction_view_refund',
};

export const UrlMap = ['default', 'boa'];

export const TitleMap = {
  boa: 'MobilePOS'
};

