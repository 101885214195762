import {Injectable} from '@angular/core';
import {Response} from '../models/response';
import {Merchant} from '../models/merchant';
import {HttpService} from './http.service';
import {Constant} from '../constants/constant';
import {Receipt} from '../models/receipt';
import {DateService} from '../utils/date.service';
import {Device} from '../models/device';
import {LoginService} from './login.service';
import {Auth} from '../models/auth';
import {Observable, Subject} from 'rxjs';
import {Authority} from '../models/authority';
import {AuthService} from './auth.service';
import {Pagination} from '../models/pagination';
import {EncryptService} from '../utils/encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor(private http: HttpService, private loginService: LoginService, private authService: AuthService) {
  }

  private merchantSubject = new Subject<any>();

  static toListMerchant(res): Pagination<Merchant> {
    let page: Pagination<Merchant> = new Pagination<Merchant>(res);
    let merchants: Array<Merchant> = [];
    for (let mer of res['MerchantList']) {
      let merchant: Merchant = new Merchant();
      merchant.merchantName = mer['MerchantName'];
      merchant.merchantID = mer['MerchantID'];
      merchant.email = mer['Email'];
      merchant.streetAddress1 = mer['Address1'];
      merchant.streetAddress2 = mer['Address2'];
      merchant.city = mer['City'];
      merchant.state = mer['State'];
      merchant.zipCode = mer['ZipCode'];
      merchant.status = mer['Status'] === Constant.ACTIVE_KEY ? Constant.ACTIVE : Constant.INACTIVE;
      merchant.createDate = DateService.getDateAndTimeString(DateService.utcToLocal(mer['CreateDate']));
      merchant.merchantKey = mer['MerchantKey'];
      merchant.isPrimary = mer['IsPrimary'];
      merchants.push(merchant);
    }
    page.data =  merchants;
    return page;
  }

  static toMerchantModel(res): Merchant {
    let mer = res['MerchantDetails'];
    let bns = res['BusinessInfoDetails'];

    let merchant: Merchant = new Merchant();
    if (mer) {
      merchant.merchantName = mer['MerchantName'];
      merchant.merchantID = mer['MerchantID'];
      merchant.email = mer['Email'];
      merchant.status = mer['Status'] === Constant.ACTIVE_KEY ? Constant.ACTIVE : Constant.INACTIVE;
      merchant.mobile = mer['Mobile'];
      merchant.phone = mer['Phone'];
      merchant.fax = mer['Fax'];
      merchant.streetAddress1 = mer['StreetAddress1'];
      merchant.streetAddress2 = mer['StreetAddress2'];
      merchant.city = mer['City'];
      merchant.state = mer['State'];
      merchant.country = mer['Country'];
      merchant.zipCode = mer['ZipCode'];
      merchant.logo = mer['Logo'];
      merchant.passcodeLength = mer['PasscodeLength'];
      merchant.description = mer['Description'];
    }

    if (bns) {
      merchant.businessName = bns['BusinessName'];
      merchant.DBA = bns['DBA'];
      merchant.saleTrxID = bns['SaleTrxID'];
      merchant.fedTaxID = bns['FedTaxID'];
      if (bns['BusinessStartDate']) merchant.businessStartDate = new Date(bns['BusinessStartDate']);
      merchant.stateTaxID = bns['StateTaxID'];
      merchant.URL = bns['URL'];
      merchant.yearSaleAmt = bns['YearSaleAmt'];
    }
    return merchant;
  }

  static toEditMerchantParam(merchant: Merchant) {
    let param = {};
    param['MerchantName'] = merchant.merchantName;
    param['MerchantID'] = merchant.merchantID;
    param['Status'] = merchant.status === Constant.ACTIVE ? Constant.ACTIVE_KEY : Constant.INACTIVE_KEY;
    param['Mobile'] = merchant.mobile;
    param['Phone'] = merchant.phone;
    param['StreetAddress1'] = merchant.streetAddress1;
    param['StreetAddress2'] = merchant.streetAddress2;
    param['City'] = merchant.city;
    param['State'] = merchant.state;
    param['Country'] = merchant.country;
    param['ZipCode'] = merchant.zipCode;
    param['Description'] = merchant.description;
    param['BusinessName'] = merchant.businessName;
    param['DBA'] = merchant.DBA;
    param['SalesTaxID'] = merchant.saleTrxID;
    param['FedTaxID'] = merchant.fedTaxID;
    param['BusinessStartDate'] = merchant.businessStartDate ? DateService.beginDateString(merchant.businessStartDate) : null;
    param['StateTaxID'] = merchant.stateTaxID;
    param['URL'] = merchant.URL;
    param['YearSaleAmt'] = merchant.yearSaleAmt;
    param['MerchantPasscodeLength'] = merchant.passcodeLength;
    param['Email'] = merchant.email;
    param['Fax'] = merchant.fax;
    return param;
  }


  static toReceiptModel(res): Receipt {
    let receipt: Receipt = new Receipt();
    receipt.logo = res['Logo'] ? Constant.IMG_BASE64_PREFIX + res['Logo'] : '';
    receipt.headerDefaultFlag = res['HeaderDefaultFlag'];
    receipt.header1 = res['Header1'];
    receipt.header2 = res['Header2'];
    receipt.header3 = res['Header3'];
    receipt.header4 = res['Header4'];
    receipt.header5 = res['Header5'];
    receipt.footerDefaultFlag = res['FooterDefaultFlag'];
    receipt.footer1 = res['Footer1'];
    receipt.footer2 = res['Footer2'];
    receipt.footer3 = res['Footer3'];
    receipt.footer4 = res['Footer4'];
    receipt.footer5 = res['Footer5'];
    receipt.defaultHeader1 = res['DefaultHeader1'];
    receipt.defaultHeader2 = res['DefaultHeader2'];
    receipt.defaultHeader3 = res['DefaultHeader3'];
    receipt.defaultHeader4 = res['DefaultHeader4'];
    receipt.defaultHeader5 = res['DefaultHeader5'];
    receipt.defaultFooter1 = res['DefaultFooter1'];
    receipt.defaultFooter2 = res['DefaultFooter2'];
    receipt.defaultFooter3 = res['DefaultFooter3'];
    receipt.defaultFooter4 = res['DefaultFooter4'];
    receipt.defaultFooter5 = res['DefaultFooter5'];
    return receipt;
  }



  static toListParam(merchantName, status, page?: Pagination<any>) {
    let param = {};
    if (merchantName) param['MerchantName'] = merchantName;
    if (status && status !== Constant.ALL) param['Status'] = status === Constant.ACTIVE ? Constant.ACTIVE_KEY : Constant.INACTIVE_KEY;
    if (page) {
      param['PageSize'] = page.pageSize;
      param['PageIndex'] = page.currPage;
    }
    return param;
  }

  static toEditReceiptParam(receipt: Receipt, merchantName) {
    let param = {};
    if (merchantName) param['MerchantName'] = merchantName;
    if (receipt.logo) param['Logo'] = receipt.logo.replace(/^data:image\/.*;base64,/, '');
    if (receipt.header1) param['Header1'] = receipt.header1;
    if (receipt.header2) param['Header2'] = receipt.header2;
    if (receipt.header3) param['Header3'] = receipt.header3;
    if (receipt.header4) param['Header4'] = receipt.header4;
    if (receipt.header5) param['Header5'] = receipt.header5;
    if (receipt.footer1) param['Footer1'] = receipt.footer1;
    if (receipt.footer2) param['Footer2'] = receipt.footer2;
    if (receipt.footer3) param['Footer3'] = receipt.footer3;
    if (receipt.footer4) param['Footer4'] = receipt.footer4;
    if (receipt.footer5) param['Footer5'] = receipt.footer5;
    param['HeaderDefaultFlag'] = receipt.headerDefaultFlag? 1 : 0;
    param['FooterDefaultFlag'] = receipt.footerDefaultFlag? 1 : 0;
    return param;
  }

  static toEditCustomFieldParam(fields) {
    let param = [];
    for (let field of fields) {
      param.push({
        FieldNameKey: field.fieldKey,
        FieldNameValue: field.fieldValue
      });
    }
    return param;
  }

  static toPageCountries(res) {
    const countries = [];
    for (const ad of res['CountryList']) {
      const param = {};
      param['key'] = ad['TwoLetterIsoCode'];
      countries.push(param);
    }
    return countries;
  }

  static toPageStates(res) {
    const states = [];
    for (const ad of res['StateProvinceList']) {
      const param = {};
      param['key'] = ad['Abbreviation'];
      states.push(param);
    }
    return states;
  }

  getMerchantList(merchantName, status, page: Pagination<any>): Promise<Response<Pagination<Merchant>>> {
    let response: Response<Pagination<Merchant>> = new Response<Pagination<Merchant>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Merchant/GetMerchantList', {
        data: MerchantService.toListParam(merchantName, status, page)
      }).subscribe(res => {
        response.data = MerchantService.toListMerchant(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  getMerchantDetails(merchantName): Promise<Response<Merchant>> {
    let response: Response<Merchant> = new Response<Merchant>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Merchant/MerchantDetails', {restful: {'MerchantName': merchantName}}).subscribe(res => {
        response.data = MerchantService.toMerchantModel(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  editMerchant(merchant: Merchant): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Merchant/EditMerchant', {
        data: MerchantService.toEditMerchantParam(merchant)
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }

  getCountryCode(): Promise<Response<Array<string>>> {
    let response: Response<Array<string>> = new Response<Array<string>>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Merchant/GetCountryCode').subscribe(res => {
        response.data = res.CountryList
        resolve(response);
      }, error => reject(error));
    });
  }

  getAllStates(country?: string): Promise<Response<Array<string>>> {
    const response: Response<Array<string>> = new Response<Array<string>>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Merchant/GetStateProvince', {
        restful: {Country: country}
      }).subscribe(res => {
        response.data = res.StateList;
        resolve(response);
      }, error => reject(error));
    });
  }

  getRegisterNum(merchantName): Promise<Response<Array<Device>>> {
    let response: Response<Array<Device>> = new Response<Array<Device>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Merchant/GetRegisterNumList', {
        data: {'MerchantName': merchantName}
      }).subscribe(res => {
        let list: Array<Device> = [];
        let tempArr = [];

        for (let item of res['RegisterNum']) {
          list.push(new Device({registerNum: item.RegisterNum}));
          tempArr.push(item.RegisterNum);
        }

        for (let ts of res['TerminalSN']) {
          let index = tempArr.indexOf(ts.RegisterNum);
          if (index === -1 || ts.RegisterNum === '') {
            list.push(new Device({
              registerNum: ts.RegisterNum,
              terminalSN: [ts.TerminalSN]
            }));
            tempArr.push(ts.RegisterNum);
          } else {
            list[index].terminalSN.push(ts.TerminalSN);
          }
        }

        response.data = list;
        resolve(response);
      }, error => reject(error));
    });
  }

  getTerminalSupportTenderType(terminalSN): Promise<Response<Array<string>>> {
    let response: Response<Array<string>> = new Response<Array<string>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Merchant/GetTerminalSupportTenderType', {
        data: {'TerminalSN': terminalSN}
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }

  getReceiptTemplate(): Promise<Response<Receipt>> {
    let response: Response<Receipt> = new Response<Receipt>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Merchant/GetReceiptTemplate').subscribe(res => {
        response.data = MerchantService.toReceiptModel(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  editReceiptTemplate(receipt: Receipt, merchantName): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Merchant/EditReceiptTemplate', {
        data: MerchantService.toEditReceiptParam(receipt, merchantName)
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }

  getCustomReceiptTemplate(): Promise<Response<Array<{ fieldKey: string, fieldValue: string }>>> {
    let response: Response<Array<{ fieldKey: string, fieldValue: string }>> =
        new Response<Array<{ fieldKey: string, fieldValue: string }>>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Merchant/GetMerchantCustomReceiptField').subscribe(res => {
        let data: Array<{ fieldKey: string, fieldValue: string }> = [];
        if (res['FieldNameInfo']) {
          for (let info of res['FieldNameInfo']) {
            data.push({
              fieldKey: info['FieldNameKey'],
              fieldValue: info['FieldNameValue']
            });
          }
        }
        response.data = data;
        resolve(response);
      }, error => reject(error));
    });
  }

  editCustomReceiptTemplate(fileds): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Merchant/EditMerchantCustomReceiptField', {
        data: {'FieldNameInfo': MerchantService.toEditCustomFieldParam(fileds)}
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }

  changeMerchant(merchantName): Promise<Response<Auth>> {
    let response: Response<Auth> = new Response<Auth>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Merchant/ChangeMerchant', {data: {'MerchantName': merchantName}}).subscribe(res => {
        response.data = LoginService.toAuthModel(res, 'IsLogin');
        resolve(response);
      }, error => reject(error));
    });
  }

  change(merchantName): Promise<Response<{ authority: Authority }>> {
    return new Promise((resolve, reject) => {
      this.changeMerchant(merchantName).then((res_auth) => {  // 获取auth
        this.authService.setAuth(res_auth.data);
        this.loginService.saveInfo().then((res) => {
          resolve(res);
        }).catch(error => reject(error));
      }).catch(error => reject(error));
    });
  }

  setPrimaryMerchant(merchantKey): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Merchant/SetPrimaryMerchant', {data: {'MerchantKey': merchantKey}}).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  editTwoFactorAuth(authenticationFlag, password): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    let salt = this.authService.getSalt();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Security/EditTwoFactorAuth', {data: {'AuthenticationFlag': authenticationFlag, 'Password': EncryptService.salt(password, salt)}}).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  get(): Observable<any> {
    return this.merchantSubject.asObservable();
  }

  send(merchant) {
    this.merchantSubject.next(merchant);
  }

  getMerchantTenderType(): Promise<Response<Array<string>>> {
    let response: Response<Array<string>> = new Response<Array<string>>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Merchant/GetMerchantTenderType').subscribe(res => {
        response.data = res['TenderTypes'];
        resolve(response);
      }, error => reject(error));
    });
  }

  getResellerNum(): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/SetUp/GetServiceNum').subscribe(res => {
        response.data = res['ServiceNum'];
        resolve(response);
      }, error => reject(error));
    });
  }
}
