import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';


import { HeaderComponent } from './header/header.component';
import { HeadTipNumberComponent } from './head-tip-number/head-tip-number.component';
import { HeadTipWebhookComponent } from './head-tip-webhook/head-tip-webhook.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { DialogComponent } from './dialog/dialog.component';
import { PaginationComponent } from './pagination/pagination.component';
import { LoadingComponent } from './loading/loading.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ChooseModifierComponent } from './choose-modifier/choose-modifier.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordRulesComponent } from './password-rules/password-rules.component';
import { DirectivesModule } from '../directives/directives.module';
import { ValidatorsModule } from '../validtors/validators.module';
import { PopItemComponent } from './pop-item/pop-item.component';
import { VerificationBoxComponent } from './verification-box/verification-box.component';
import { ChangeEmailComponent } from './change-email/change-email.component';

import { DynamicFieldDirective } from './dynamic-form/dynamic-field/dynamic-field.directive';
import { FormInputComponent } from './dynamic-form/form-input/form-input.component';
import { FormSelectComponent } from './dynamic-form/form-select/form-select.component';
import { FormCheckboxGroupComponent } from './dynamic-form/form-checkbox-group/form-checkbox-group.component';
import { FormSwicthComponent } from './dynamic-form/form-switch/form-switch.component';
import { TransactionComponent } from './transaction/transaction.component';
import { FooterComponent } from './footer/footer.component';
import { SimplePaginationComponent } from './simple-pagination/simple-pagination.component';

import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { ResDialogComponent } from './res-dialog/res-dialog.component';
import { ProfileComponent } from './profile/profile-dialog.component';

import { SearchHeaderComponent } from './search-header/search-header.component';
import { MultSelectComponent } from './mult-select/mult-select.component';
@NgModule({
  declarations: [
    LeftMenuComponent,
    HeaderComponent,
    HeadTipNumberComponent,
    HeadTipWebhookComponent,
    FooterComponent,
    DialogComponent,
    ResDialogComponent,
    SearchHeaderComponent,
    MultSelectComponent,
    PaginationComponent,
    LoadingComponent,
    DropdownComponent,
    ChooseModifierComponent,
    CheckboxGroupComponent,
    PasswordRulesComponent,
    ChangeEmailComponent,
    TransactionComponent,
    PopItemComponent,
    VerificationBoxComponent,

    DynamicFieldDirective,
    FormInputComponent,
    FormSelectComponent,
    FormCheckboxGroupComponent,
    FormSwicthComponent,
    SimplePaginationComponent,

    CustomDialogComponent,
    ProfileComponent
  ],
  imports: [
    DirectivesModule,
    RouterModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ClickOutsideModule,
    NgbPopoverModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    ValidatorsModule,
    DateInputsModule
  ],
  exports: [
    HeaderComponent,
    HeadTipNumberComponent,
    HeadTipWebhookComponent,
    FooterComponent,
    LeftMenuComponent,
    PaginationComponent,
    LoadingComponent,
    DropdownComponent,
    CheckboxGroupComponent,
    PasswordRulesComponent,
    PopItemComponent,
    VerificationBoxComponent,
    ChangeEmailComponent,
    TransactionComponent,
    DynamicFieldDirective,
    ChooseModifierComponent,
    SimplePaginationComponent,
    CustomDialogComponent,
    ProfileComponent,
    SearchHeaderComponent,
    MultSelectComponent,
  ],
  entryComponents: [
    HeadTipNumberComponent,
    HeadTipWebhookComponent,
    DialogComponent,
    ResDialogComponent,
    SearchHeaderComponent,
    MultSelectComponent,
    PopItemComponent,
    FormInputComponent,
    FormSelectComponent,
    FormCheckboxGroupComponent,
    FormSwicthComponent
  ]
})
export class ComponentsModule {
}
