import {Component, OnInit} from '@angular/core';
import {DialogService} from '../../utils/dialog.service';
import {ForgotService} from '../../services/login.service';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {LoadingService} from '../../utils/loading.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  host: {class: 'up-login-container'},
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  step: number = 0;
  forgotForm: FormGroup;
  submitted: boolean = false;
  error_msg: string = '';

  captchaImg: string;
  token: string = '';

  constructor(private dialog: DialogService, private forgotService: ForgotService, private formBuilder: FormBuilder, private loading: LoadingService) {
  }

  ngOnInit() {
    this.initForm();
    this.getCaptcha();
  }

  get f() {
    return this.forgotForm.controls;
  }

  initForm() {
    this.forgotForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
          captchaCode: ['', Validators.required]
        }
    );
  }

  sendForgotEmail() {
    this.submitted = true;
    if (this.forgotForm.invalid) return;
    this.loading.show();
    this.error_msg = '';
    this.forgotService.sendForgotEmail2(this.f.email.value, this.f.captchaCode.value, this.token).then(() => {
      this.step++;
    }).catch(error => {this.error_msg = this.dialog.error_msg(error); })
    .finally(() => {
      this.submitted = false;
      this.getCaptcha();
      this.loading.hide();
    });
  }

  getCaptcha() {
    this.loading.show();
    this.forgotService.getCaptcha().then((res) => {
      this.f.captchaCode.setValue('');
      this.captchaImg = res.data.captchaImg;
      this.updateToken(res.data.captchaToken);
    }).catch((res) => {
      this.dialog.error(res);
    }).finally(() => this.loading.hide());
  }

  updateToken(token) {
    if (token) {
      this.token = token;
    }
  }
}
