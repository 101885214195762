export const PermissionAuthorityMap = {
  DASHBOARD_MODULE: 'dashboard',

  TRANSACTION_MODULE: 'transaction',
  TRANSACTION_VIEWALL: 'transaction_view_all',
  TRANSACTION_VIEWREFUND: 'transaction_view_refund',
  TRANSACTION_VIEWVOID: 'transaction_view_void',
  TRANSACTION_DOWNLOADTRANS: 'transaction_download_transaction',
  TRANSACTION_ALLSALESTRANS: 'transaction_all',

  REPORT_MODULE: 'report',
  REPORT_DOWNLOADALLTRANSATIONS: 'report_download_transaction',
  REPORT_TRANSACTIONREPORT: 'report_transaction',
  REPORT_EMPLOYEEREPORT: 'report_employee',
  REPORT_ALLSALESTRANS: 'report_transaction_all',

  USER_MODULE: 'user',
  USER_VIEWALLUSER: 'user_view_all',
  USER_VIEWUSERDETAIL: 'user_view_detail',
  USER_ADDUSER: 'user_add',
  USER_EDITUSER: 'user_edit',
  USER_EDITCASUALUSEREMAIL: 'user_casual_email_edit',
  USER_RESETPASSCODE: 'user_reset_passcode',
  USER_RESETPASSWORD: 'user_reset_password',

  ROLE_MODULE: 'role',
  ROLE_VIEWALLROLE: 'role_view_all',
  ROLE_ADDROLE: 'role_add',
  ROLE_EDITROLE: 'role_edit',
  ROLE_DELETEROLE: 'role_delete',

  MERCHANT_MODULE: 'merchant',
  MERCHANT_VIEWALLMERCHANT: 'merchant_view_all',
  MERCHANT_VIEWMERCHANTDETAIL: 'merchant_view_detail',
  MERCHANT_EDITMERCHANT: 'merchant_edit',
  MERCHANT_VIEWTERMINAL: 'merchant_terminal_view',
  MERCHANTPROCESSOR_VIEW: 'merchant_processor_view',

  RECEIPT_MODULE: 'receipt',
  MERCHANT_VIEWRECEIPT: 'merchant_receipt_view',
  MERCHANT_EDITRECEIPT: 'merchant_receipt_edit',
  MERCHANT_VIEWCUSTOMRECEIPTFIELD: 'merchant_custom_receipt_view',
  MERCHANT_EDITCUSTOMRECEIPTFIELD: 'merchant_custom_receipt_edit',
  PAYMENT_RECEIPT:'payment_receipt',

  INVENTORY_MODULE: 'inventory',
  ITEM_MODULE: 'item',
  ITEM_VIEW: 'item_view',
  ITEM_ADD: 'item_add',
  ITEM_EDIT: 'item_edit',
  ITEM_DELETE: 'item_delete',
  ITEM_MODIFIER: 'item_modifier',

  CATEGORY_MODULE: 'category',
  CATEGORY_VIEW: 'category_view',
  CATEGORY_ADD: 'category_add',
  CATEGORY_EDIT: 'category_edit',
  CATEGORY_DELETE: 'category_delete',

  STOCK_MODULE: 'stock',
  STOCKMANAGEMENT_VIEW: 'stock_management_view',
  STOCKMANAGEMENT_MANAGE: 'stock_management_manage',

  MODIFIERGROUP_MODULE: 'modifier_group',
  MODIFIERGROUP_VIEW: 'modifier_group_view',
  MODIFIERGROUP_ADD: 'modifier_group_add',
  MODIFIERGROUP_EDIT: 'modifier_group_edit',
  MODIFIERGROUP_DELETE: 'modifier_group_delete',

  TAX_MODULE: 'tax',
  TAX_VIEW: 'tax_view',
  TAX_ADD: 'tax_add',
  TAX_EDIT: 'tax_edit',
  TAX_DELETE: 'tax_delete',
  TAX_OVERRIDETAXCATEGORY: 'tax_override_taxcategory',

  TAXCATEGORY_MODULE: 'tax_category',
  TAXCATEGORY_VIEW: 'tax_category_view',
  TAXCATEGORY_ADD: 'tax_category_add',
  TAXCATEGORY_EDIT: 'tax_category_edit',
  TAXCATEGORY_DELETE: 'tax_category_delete',

  SETTINGCONFIG_MODULE: 'setting_config',
  SETTINGCONFIG_VIEW: 'setting_config_view',
  SETTINGCONFIG_EDIT: 'setting_config_edit',

  ORDER_MODULE:'order',
  ORDER_VIEW:'order_view'


};
