import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Item} from '../../models/Item';
import {Pagination} from '../../models/pagination';
import {ItemService} from '../../services/item.service';
import {LoadingService} from '../../utils/loading.service';
import {Modal} from '../../utils/modal.service';
import {DialogService} from '../../utils/dialog.service';

@Component({
  selector: 'app-pop-item',
  templateUrl: './pop-item.component.html',
  styleUrls: ['./pop-item.component.scss']
})

export class PopItemComponent implements OnInit {

  itemName: string;
  itemsList: Array<Item> = [];
  pagination: Pagination<Item> = new Pagination<Item>();
  disabledList: Array<boolean> = [];

  selectItemList: Array<{ Name: string, ModifierGuid: string }> = [];
  selectItemGuidList: Array<string> = [];
  masterCheck: boolean;

  searchName: string;

  param: { itemName: string, isModifier: string, categoryGuid: string, exceptCategoryFlag: number };

  @Output() selectItemsChange =  new EventEmitter();
  @Input() modal: Modal;
  @Input() selected: Array<string> = [];
  @Input() isModifier: boolean;
  @Input() categoryGuid: string;
  @Input() exceptCategoryFlag: number;

  constructor(private loading: LoadingService, private itemService: ItemService, private dialog: DialogService) {}

  ngOnInit() {
    this.pagination.pageSize = 5;
    this.param = {
      itemName: '',
      isModifier: this.isModifier ? 'Only Modifier' : '',
      categoryGuid: this.categoryGuid || null,
      exceptCategoryFlag: this.exceptCategoryFlag || null
    };
    this.search();
  }

  search() {
    this.pagination.currPage = 1;
    this.param.itemName = this.searchName;
    this.pageChange();
  }

  pageChange() {
    this.itemService.getItemsList(this.param, this.pagination).then((res) => {
      let pagination: Pagination<Item> = res.data;
      this.pagination = pagination;
      let itemsList: Array<Item> = pagination.data;
      let disabledList = new Array<boolean>(this.itemsList.length);
      let masterCheck: boolean = true;
      itemsList.forEach((item, i) => {
        let preSelectFlag = this.selected.indexOf(item.guid) !== -1;
        disabledList[i] = preSelectFlag;
        item.checked = preSelectFlag || this.selectItemGuidList.indexOf(item.guid) !== -1;
        if (!item.checked) masterCheck = false;
      });
      this.itemsList = pagination.data;
      this.disabledList = disabledList;
      this.masterCheck = masterCheck;
    }).catch(res => this.dialog.error(res)).finally(() => this.loading.hide());
  }

  hide() {
    this.modal.hide();
  }

  checkAll() {
    this.itemsList.forEach((item, i) => {
      if (!this.disabledList[i]) {
        item.checked = this.masterCheck;
        this.recordSelectItem(item);
      }
    });
  }

  itemCheckedChange(item) {
    if (item.checked === false) this.masterCheck = false;
    else {
      let temp = true;
      this.itemsList.forEach((el) => {
        if (!el.checked) temp = false;
      });
      this.masterCheck = temp;
    }
    this.recordSelectItem(item);
  }

  recordSelectItem(item: Item) {
    const i = this.selectItemGuidList.indexOf(item.guid);
    if (item.checked) {
      if(i === -1){
        this.selectItemList.push({Name: item.itemName, ModifierGuid: item.guid});
        this.selectItemGuidList.push(item.guid);
      }
    } else {
      this.selectItemList.splice(i, 1);
      this.selectItemGuidList.splice(i, 1);
    }
  }

  submitItems() {
    this.modal.hide();
    this.selectItemsChange.emit(this.selectItemList);
  }
}
