import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CallbackService } from 'src/app/services/callback.service';
import { CallService } from 'src/app/services/call.service';
import { ProfileService } from 'src/app/services/profile.service';
@Component({
  selector: 'app-callback',
  templateUrl: './callback.html',
  styleUrls: ['./callback.scss']
})
export class CallbackComponent implements OnInit {

    authCode;

    showloading = true;

    constructor(private routerInfo: ActivatedRoute, private callbackService: CallbackService,
      private router: Router, private authService: AuthService, private profileService: ProfileService
    ) {
    }

    ngOnInit(): void {
        this.routerInfo.queryParams.subscribe((params: Params)=>this.authCode=params['code']);
        if(this.authCode) {
          this.exchangeToken();
        }
    }

    getCookieWithValue(name) {
      // 获取所有cookie
      const cookies = document.cookie.split('; ');
      // 检查是否有匹配的cookie和值
      const idToken =  cookies.find(cookie => {
        const [cookieName, cookieValue] = cookie.split('=');
        return cookieName === name && cookieValue;
      });

      const [cookieName, cookieValue] = idToken.split('=');
      return cookieValue;
    }

    async exchangeToken() {
      try {
        await this.callbackService.exchangeToken(this.authCode);

        // profile.data.logins_count

        this.showloading = false;
        const idToken = this.getCookieWithValue('idToken')
        if(idToken) {
          const profile = (await this.profileService.getProfile()).data;
          this.authService.setProfile(profile);
          this.authService.setIdTokenInfo(profile.app_metadata.store_id, profile.app_metadata.merchant_id);
          this.authService.setLoginFlag();

          if(profile.logins_count > 1) {
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/number-register'], {queryParams: {firstTime: true}})
          }

        }
      } catch (e) {
        console.log(e)
      }
       
    }

    parseJwt(token) {
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('JWT must have 3 parts');
      }
      
      const header = JSON.parse(atob(parts[0]));
      const payload = JSON.parse(atob(parts[1]));
     
      return {
        header,
        payload,
        signature: parts[2]
      };
    }
}