import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  constructor() {
  }

  static salt(val, salt) {
    return CryptoJS.SHA512(val + salt).toString(CryptoJS.enc.Hex);
  }

  static signature(method: string, text: string, key: string) {
    if (method === 'GET') text = text.replace(/'/g, '%27');
    return CryptoJS.HmacSHA256(text, key).toString(CryptoJS.enc.Hex);
  }

  // TODO: figure out what is the key and text
  static signatureData(method: string, text: string) {
    if (method === 'GET') text = text.replace(/'/g, '%27');
    return CryptoJS.HmacSHA256(text, 'HMACSHA256)').toString(CryptoJS.enc.Hex);
  }
}
