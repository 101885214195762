import { Directive, HostBinding } from '@angular/core';
import { GeneralService } from '../utils/general.service';
import { Subscription } from 'rxjs';
@Directive({
  selector: '[isMobile]'
})
export class MobileDirective {
  @HostBinding('class.mobile') isMobile: boolean;

  isPcChange: Subscription

  constructor(private generalService: GeneralService) {
    this.isMobile = !this.generalService.isPC();

    this.isPcChange = this.generalService.getPCAgentChanges().subscribe(isPc => {
      this.isMobile = !isPc;
    })
  }
}
