import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DropdownComponent,
    multi: true
  }]
})

export class DropdownComponent implements ControlValueAccessor, OnChanges {

  @Input() withKey: boolean;
  @Input() multipleLines: boolean = false;
  @Input() list;
  @Output() valueChange = new EventEmitter();

  @Output() loadChange = new EventEmitter();
  @Input() currPage;
  @Input() totalPage;
  @Input() placeholder;

  showName: string;

  value = '';
  disabled: boolean;
  onChangeListener; // 改变值回调
  onTouchedListener; // 交互回调

  constructor(private ele: ElementRef) { }

  ngOnInit() {
    this.listen();
  }

  listen() {
    let divEle = this.ele.nativeElement.querySelector('.dropdown-menu');
    divEle.addEventListener('scroll',this.scroll,true)
  }
 
  scroll=(e)=>{
    if(this.currPage == this.totalPage) this.removeListen(); 
    if (e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight) {
      this.currPage ++;
      this.loadChange.emit(this.currPage);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateShowName(this.value);
  }
  removeListen(){
    let divEle = this.ele.nativeElement.querySelector('.dropdown-menu')
    divEle.removeEventListener('scroll', this.scroll, true);
  }

  /*  ngOnChanges(changes: SimpleChanges): void {
      if (this.withKey) {
        for (let item of this.list) {
          if (item.key === this.value) {
            this.showName = item.val;
            break;
          }
        }
      } else this.showName = this.value;
     }*/

  select(item) {
    /*if (this.withKey) {
      this.showName = item.val;
      this._valueChange(item.key);
    } else {
      this.showName = item;
      this._valueChange(item);
    }*/
    this._valueChange(this.withKey ? item.val : item);
  }

  _valueChange(val) {
    this.value = val;
    this.updateShowName(val);
    this.valueChange.emit(val);
    this.onChangeListener(val); // 告诉form，你的表单值改变成了payload
    this.onTouchedListener(); // 告诉form，你的表单有交互发生
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.updateShowName(obj);
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn; // 保存这个函数
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn; // 保存这个函数
  }

  updateShowName(val) {
    if (!val) this.showName = '';
    if (this.withKey) {
      for (let item of this.list) {
        if (item.val === val) {
          this.showName = item.key;
          break;
        }
      }
    } else this.showName = val;
  }

  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
}
