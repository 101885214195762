import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CheckboxGroupComponent,
    multi: true
  }]
})

export class CheckboxGroupComponent implements ControlValueAccessor, OnInit {

  @Input() list: Array<{ key: any, val: any }>;

  value: Array<any> = [];
  disabled: boolean;
  onChangeListener; // 改变值回调
  onTouchedListener; // 交互回调

  checks: Array<{ key: any, val: any, checked: boolean }> = [];

  constructor() {
  }

  ngOnInit() {
    this.checks = JSON.parse(JSON.stringify(this.list));
  }

  _valueChange() {
    let val = this.getBoxChecked();
    this.value = val;
    this.onChangeListener(val); // 告诉form，你的表单值改变成了payload
    this.onTouchedListener(); // 告诉form，你的表单有交互发生
  }

  writeValue(obj: any): void {
    let val = obj ? obj : [];
    this.value = val;
    this.updateBoxChecked(val);
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn; // 保存这个函数
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn; // 保存这个函数
  }

  updateBoxChecked(obj) {
    for (let check of this.checks) {
      if (obj.indexOf(check.val) !== -1) check.checked = true;
    }
  }

  getBoxChecked(): Array<any> {
    let val: Array<any> = [];
    for (let check of this.checks) {
      if (check.checked) val.push(check.val);
    }
    return val;
  }

  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
}
