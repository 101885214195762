import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Router } from "@angular/router";
import { EncryptService } from "../utils/encrypt.service";
import { Response } from "../models/response";
import { Auth } from "../models/auth";
import { DateService } from "../utils/date.service";
import { AuthService } from "./auth.service";
import { ProfileService } from "./profile.service";
import { RoleService } from "./role.service";
import { Authority } from "../models/authority";
import { Constant } from "../constants/constant";
import { DialogService } from "../utils/dialog.service";
import { UrlMap } from "../constants/custom-map";
import { HttpClient } from "@angular/common/http";              // 用处不大。就是拿到 baseUrl
import {Observable, throwError} from 'rxjs';                          // Observable 几乎是用作ts返回值类型，throwError 没什么用
import {catchError, map} from 'rxjs/operators';

/**
 * 需要确认以下几件事情：
 * 1. request type (get/post)
 *    两个现在都练习一下
 * 2. request url
 *    随便先写一个
 * 3. request params
 *    假设有email，password
 * 4. response details(最好能单独写一个类)
 *    假设有ResponseCode，ResponseMsg
 *    假设ResponseCode=000000表示成功注册
 *    其他code表示注册失败
 *      失败时的逻辑放在catch中
 * 5. AccessKeyId, token, ...
 */

@Injectable({
  providedIn: "root",
})
export class SignUpService {
  constructor(
    private http: HttpService,
    private authService: AuthService,
    private router: Router,
    private dialog: DialogService,
    private profileService: ProfileService,
    private roleService: RoleService,
    private httpClient: HttpClient
  ) {}

  async getSalt(email: string): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();

    try {
      const res = await this.http
        .get("/Merchant/Login/GetForeEndSalt", {
          restful: { Email: email },
        })
        .toPromise();
      if (res["ForeEndSalt"]) {
        this.authService.setSalt(res["ForeEndSalt"]);
      }
      response.data = res["ForeEndSalt"];
      return response;
    } catch (e) {
      console.log("error1: " + JSON.stringify(e, null, 4));
    }
  }

  async getSignUpResponse(
    email: string,
    password: string,
    salt: string
  ): Promise<Response<void>> {
    let response: Response<void> = new Response<void>();
    try {
      //! this is GET type
      // const res = await this.http.get("/Merchant/SignUp/XXXXXX", {
      //   restful: { 'Email': email, 'Password': EncryptService.salt(password, salt) },
      // }).toPromise()
      //! this is POST type
      const res = await this.http
        .post("/Merchant/SignUp/XXXXXX", {
          data: { Email: email, Password: EncryptService.salt(password, salt) },
        })
        .toPromise();

      console.log("111");

      response.resultCode = res["resultCode"];
      response.resultMsg = res["resultMsg"];
      return response;
    } catch (e) {
      throw e;
    }
  }

  async signUp(data: {}): Promise<Response<void>> {
    let response: Response<void> = new Response<void>();

    try {

    const res = await this.http.post("/AIphone/v1/Register", {
          data: {
            MerchantName: data["merchantName"],
            UserEmail: data["userEmail"],
            FirstName: data["firstName"],
            LastName: data["lastName"],
            userType: data["userType"]
          },
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .toPromise();

      response.resultCode = res['ResultCode']
      response.resultMsg = res['ResultMsg']
      return response;
    } catch (e) {
      console.log(e)
      throw e;
    }
  }
}
