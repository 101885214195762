import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class LoadingService {

  private loadingSubject = new Subject<{ show: boolean, msg?: string, hideAll?: boolean }>();
  hidden: boolean = false;

  constructor() {}

  get(): Observable<{ show: boolean, msg?: string, hideAll?: boolean }> {
    return this.loadingSubject.asObservable();
  }

  show(msg: string = '') {
    this.hidden = false;
    this.loadingSubject.next({show: true, msg});
  }

  hide() {
    this.hidden = true;
    this.loadingSubject.next({show: false});
  }
  hideAll() {
    this.hidden = true;
    this.loadingSubject.next({show: false, hideAll: true});
  }
}
