import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Constant } from 'src/app/constants/constant';
import * as Lodash from 'lodash'

@Component({
  selector: 'app-simple-pagination',
  templateUrl: './simple-pagination.component.html',
  styleUrls: ['./simple-pagination.component.scss']
})
export class SimplePaginationComponent implements OnInit {


  constructor() {
  }

  @Input() pageSize;
  @Output() pageSizeChange = new EventEmitter();

  @Input() totalPage: number;

  @Input() currPage: number = 5;
  @Input() totalCounts: number = 0
  @Output() currPageChange = new EventEmitter();

  @Output() private onChangePage = new EventEmitter();
  showErrTip: boolean = false

  display: { pre: number, total: number } = {
    pre: Constant.ui_pagination_pre,
    total: Constant.ui_pagination_total - 1
  };
  pageSizeList: Array<number> = Constant.ui_pagination_pageSizeList;
  errTip: string = 'This value must be positive integer number'
  _currPageChange: Function

  ngOnInit() {
    this._currPageChange = this._debounceCurrpageChange()
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  _pageSizeChange = () => {
    this.pageSizeChange.emit(this.pageSize);
    this.currPageChange.emit(1);
    this.currPage = 1
    const reg = /^[1-9]\d*$/
    if (reg.test(this.currPage.toString())) {
      this.showErrTip = false
      this.onChangePage.emit();
    }
    
  };

  _debounceCurrpageChange() {
    return Lodash.debounce((page) => {
      let pageStr = page.toString().replace(/\D+/g, '')
      this.currPage = pageStr
      this.pageSizeChange.emit(this.pageSize);
      this.currPageChange.emit(pageStr);
      const reg = /^[1-9]\d*$/
      if (!pageStr || pageStr && !reg.test(pageStr)) {
        this.showErrTip = true
      }else {
        this.showErrTip = false
        this.onChangePage.emit();
      }
    }, 300)
  }

  _prePageChange() {
    if (this.currPage > 1) {
      this._currPageChange(this.currPage - 1)
    }
  }
  _nextPageChange() {
    if (this.totalPage && this.totalPage > this.currPage) {
      this._currPageChange(this.currPage + 1)
    }
  }
}
