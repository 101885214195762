import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { DialogService } from '../../utils/dialog.service';
import { LoadingService } from '../../utils/loading.service';
import { FormGroup } from '@angular/forms';
import { FindFirstPage } from '../../constants/menu';

@Component({
  selector: 'app-two-factor-login',
  templateUrl: './two-factor-login.component.html',
  host: { class: 'up-login-container' },
  styleUrls: ['./two-factor-login.component.scss']
})
export class TwoFactorLoginComponent implements OnInit {

  email: string;
  hidemail: string;
  hadsend: boolean = false
  submitted = false;
  error_msg: string;
  waiting: number = 0;

  verificationCode: string = '';
  @ViewChild('VerificationBox', {static: true}) boxElement: any;

  constructor(private router: Router, private loading: LoadingService, private loginService: LoginService,
              private dialog: DialogService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((res) => {
      this.email = res.email;
    });
  }

  ngOnInit() {
    this.sendCode();
    this.hidemail = this.email.substring(3);
  }

  login() {
    this.submitted = true;

    this.error_msg = null;
    this.loading.show();
    this.loginService.twoFactorAuth(this.verificationCode).then((res) => {
      let router = FindFirstPage(res.data.authority);
      this.router.navigate([router]);
    }).catch((res) => {
      let alert = this.dialog.error(res);
      if (alert && res.resultCode === '000001') alert.onHidden.subscribe(() => this.router.navigate(['/reset-password'], {queryParams: {email: this.email}}));
      // this.error_msg = this.dialog.error_msg(res);
    }).finally(() => this.loading.hide());
  }

  sendCode() {
    this.error_msg = '';
    this.loading.show();
    this.loginService.sendCode().then((res) => {
      this.hadsend = true;
      this.waiting = 60;
      this.countdown();
    }).catch((res) => {
      if (res.resultCode === '999398') {
        this.router.navigate(['/login']);
      } else {
        this.error_msg = this.dialog.error_msg(res);
      }
    }).finally(() => {
      this.loading.hide();
    });
  }

  countdown() {
    if (this.waiting > 0) {
      setTimeout(() => {
        this.waiting--;
        this.countdown();
      }, 1000);
    }
  }
}
