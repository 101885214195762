export class Merchant {
  merchantName: string;
  merchantID: string;
  email: string;
  address: string;
  status: string;
  createDate: string;
  merchantKey: string;
  mobile: string;
  phone: string;
  fax: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  logo: string;

  businessName: string;
  DBA: string;
  saleTrxID: string;
  fedTaxID: string;
  businessStartDate: Date;
  stateTaxID: string;
  URL: string;
  yearSaleAmt: string;
  lastLogon: string;

  description: string;
  isPrimary: string;

  passcodeLength: number;
}
