import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../services/login.service';
import {LoadingService} from '../../utils/loading.service';
import {DialogService} from '../../utils/dialog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ValidatorsService} from '../../validtors/validators.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  host: {class: 'up-login-container'},
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {

  email: string;
  step: number = 0;

  submitted: boolean = false;
  passwordForm: FormGroup;

  constructor( private loading: LoadingService, private dialog: DialogService, private formBuilder: FormBuilder, private router: Router, private loginService: LoginService,private route: ActivatedRoute) {
    this.route.queryParams.subscribe((res) => {
      this.email = res.email;
    });
  }

  ngOnInit() {
    this.initPasswordForm();
  }

  initPasswordForm() {
    this.passwordForm = this.formBuilder.group({
          newPwd: ['', [Validators.required, ValidatorsService.password]],
          confirmPwd: ['', Validators.required]
        },
        {
          validator: ValidatorsService.compare('newPwd', 'confirmPwd')
        }
    );
  }

  toLogin = () => {
    this.router.navigate(['/login']);
  };

  get pwd() {
    return this.passwordForm.controls;
  }

  setPassword() {
    this.submitted = true;
    if (this.passwordForm.invalid) return;
    this.loading.show();
    this.loginService.updateLogin(this.pwd.newPwd.value, this.pwd.confirmPwd.value, this.email).then(() => {
      this.step++;
    }).catch(res => this.dialog.error(res))
      .finally(() => this.loading.hide());
  }

}
