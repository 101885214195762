import { Component, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SettingField  } from '../../../models/settingConfig';
import { Field } from '../../../models/field.interface';


import { SettingConfigService } from '../../../services/setting-config.service';
import { CompileShallowModuleMetadata } from '@angular/compiler';

@Component({
  selector: 'app-form-swicth',
  template: `
  <div class="form-group row" [formGroup]="group">
  <label class="up-form-label" [class.required]="config.required === 1">{{ config.displayName}}</label>
  <div class="up-form-control">
  <input type="checkbox" class="sp-switch btn-default" [formControlName]="config.fieldName" 
   (ngModelChange)="changed($event, config, fieldList)">
  </div>
  `,
  styles: [' .up-form-label {flex: 1;max-width: 100%;text-align: left !important;overflow: hidden;text-overflow:ellipsis; white-space: nowrap;}'],
})
export class FormSwicthComponent implements Field {
  config: SettingField;
  group: FormGroup;
  fieldList: Array<SettingField>;

  changed(val, settingField: SettingField, group: Array<SettingField>) {
    settingField.configValue = val;
    SettingConfigService.dependChanged(settingField, group);
  }

}
