import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Transaction} from '../../models/transaction';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit, OnChanges {

  @Input() transaction: Transaction;

  @ViewChild('signatureData', {static: false}) signatureData: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.createSignSVG(this.transaction.signatureData);
  }

  createSignSVG(signSource) {
    if (!signSource || signSource.length <= 2) return;

    let strokeList = signSource.split('^0,65535^'),
        svgAttributes = [], paths = [],
        Ymin = 99999, Ymax = 0,
        Xmin = 99999, Xmax = 0;
    for (let stroke of strokeList) {
      let tempArray = stroke.match(/\d+/g);
      if (tempArray) {
        let temps = [];
        for (let j = 0; j < tempArray.length; j++) {
          let k = parseInt(tempArray[j], 10);
          temps.push(k);
          if (k < 65535 && k > 0) {
            if (j % 2 === 0) {
              Xmin = Xmin < k ? Xmin : k;
              Xmax = Xmax > k ? Xmax : k;
            } else {
              Ymin = Ymin < k ? Ymin : k;
              Ymax = Ymax > k ? Ymax : k;
            }
          }
        }
        paths.push(temps);
      }
    }
    if (!paths.length) return;
    Xmax = Xmax + 10;
    Ymax = Ymax + 10;
    let ratio = (Xmax - Xmin) > 60 ? 180 / (Xmax - Xmin) : 1;
    svgAttributes.push('width="' + Xmax * ratio + '"');
    svgAttributes.push('height="' + Ymax * ratio + '"');

    let signSvg = '<svg ' + svgAttributes.join(' ') + '>';

    for (let temps of paths) {
      let d = 'M ';
      for (let j = 0; j < temps.length; j++) {
        d += temps[j] * ratio + ' ';
        if (j % 2 === 1 && j !== temps.length - 1) d += 'L';
      }
      signSvg += '<path fill="none" stroke="#000000" d="' + d + '"></path>'; /*stroke-width="' + 1.5 * ratio + '"*/
    }
    signSvg += '</svg>';
    if(this.signatureData)this.signatureData.nativeElement.innerHTML = signSvg;
  }
}
