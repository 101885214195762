import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'head-tip-webhook',
  templateUrl: './head-tip-webhook.component.html',
  styleUrls: ['./head-tip-webhook.component.scss'],
})
export class HeadTipWebhookComponent implements OnInit {


  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  goPage() {
    this.router.navigate(['/webhook']);
    // remove head
  }
}
