export class Auth {
  uptmpasid: string;
  accessKeyID: string;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  merchantUserKey: string;

  expires: string;
  expiresIn: string;
  issued: string;

  lastLogon: string;
  isTwoFactor: boolean;
  // firstName: string;
  // lastName: string;
  merchantNameList: Array<string>;
  primaryMerchantName: string;
  merchantName: string;
  isPrimaryUser: boolean;
  authToken: string;

  constructor(options: {
    uptmpasid?: string,
    accessKeyID?: string,
    accessToken?: string,
    refreshToken? :string,
    tokenType?: string,
    merchantUserKey?: string,
    expires?: string,
    expiresIn?: string,
    issued?: string,
    lastLogon?: string,
    isTwoFactor?: boolean,
    // firstName?: string,
    // lastName?: string,
    merchantNameList?: Array<string>,
    merchantName?: string,
    primaryMerchantName?: string,
    isPrimaryUser?: boolean;
    authToken?: string;
  } = {}) {
    this.uptmpasid = options.uptmpasid || '';
    this.accessKeyID = options.accessKeyID || '';
    this.accessToken = options.accessToken || '';
    this.refreshToken = options.refreshToken || '';
    this.isTwoFactor = options.isTwoFactor || false;
    this.tokenType = options.tokenType || '';
    this.merchantUserKey = options.merchantUserKey || '';
    this.expires = options.expires || '';
    this.expiresIn = options.expiresIn || '';
    this.issued = options.issued || '';
    this.lastLogon = options.lastLogon || '';
    // this.firstName = options.firstName || '';
    // this.lastName = options.lastName || '';
    this.merchantNameList = options.merchantNameList || [];
    this.merchantName = options.merchantName || '';
    this.primaryMerchantName = options.primaryMerchantName || '';
    this.isPrimaryUser = options.isPrimaryUser || false;
    this.authToken = options.authToken || '';
  }
}
