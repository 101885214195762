import {Injectable} from '@angular/core';
import {Response} from '../models/response';
import {Role} from '../models/role';
import {HttpService} from './http.service';
import {Permission} from '../models/permission';
import {Constant} from '../constants/constant';
import {DateService} from '../utils/date.service';
import {Authority} from '../models/authority';
import {PermissionAuthorityMap} from '../constants/permission-authority-map';
import {ForbiddenAuthorityMap} from '../constants/custom-map';
import {CustomService} from './custom.service';
import {Pagination} from '../models/pagination';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpService) {
  }

  static toListRole(res): Pagination<Role> {
    let page: Pagination<Role> = new Pagination<Role>(res);
    let roles: Array<Role> = [];
    for (let r of res['RoleList']) {
      let role: Role = new Role();
      role.roleKey = r['RoleKey'];
      role.roleName = r['RoleName'];
      role.authority = r['Authority'];
      role.status = r['Status'] === Constant.ACTIVE_KEY ? Constant.ACTIVE : Constant.INACTIVE;
      role.createDate = DateService.getDateAndTimeString(DateService.utcToLocal(r['CreateDate']));
      roles.push(role);
    }
    page.data = roles;
    return page;
  }

  static toAllPermission(res): Array<Permission> {
    let forbidden = ForbiddenAuthorityMap[CustomService.getUrlPrefix()];
    let permissions: Array<Permission> = [];
    for (let per of res) {
      if (per['Status'] !== Constant.ACTIVE_KEY) continue;
      let key = PermissionAuthorityMap[per['PermissionCode']];
      if (key && forbidden && forbidden.indexOf(key) !== -1) continue;
      let permission: Permission = new Permission();
      permission.permissionCode = per['PermissionCode'];
      permission.permissionDisplayName = per['PermissionDisplayName'];
      permission.parentPermissionCode = per['ParentPermissionCode'];
      permission.description = per['Description'];
      permission.restrictFlag = per['RestrictFlag'];
      permission.dependentPermissionCodeList = per['DependentPermissionCodeList'];
      permissions.push(permission);
    }
    return permissions;
  }

  static toCheckedPermission(res): Array<string> {
    let checkedList = [];
    for (let per of res['Permission']) {
      if (per['Status'] === Constant.ACTIVE_KEY) checkedList.push(per['PermissionCode']);
    }
    return checkedList;
  }

  static toEditPermissionParam(roleName, permissions: Array<Permission>) {
    let paramPer = [];
    for (let per of permissions) {
      paramPer.push({
        'PermissionCode': per.permissionCode,
        'Status': Constant.ACTIVE_KEY
      });
    }
    return {'RoleName': roleName, 'Permission': paramPer};
  }

  static toAuthority(checkedList: Array<string>): Authority {
    let forbidden = ForbiddenAuthorityMap[CustomService.getUrlPrefix()];
    let authority: Authority = new Authority();
    for (let checked of checkedList) {
      let key = PermissionAuthorityMap[checked];
      if (key && (!forbidden || forbidden.indexOf(key) === -1)) authority[key] = true;
    }
    return authority;
  }

  getRoleList(page: Pagination<any>): Promise<Response<Pagination<Role>>> {
    let response: Response<Pagination<Role>> = new Response<Pagination<Role>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/User/GetRoleList',{
        data:{
          'PageSize' : page.pageSize,
          'PageIndex' : page.currPage
        }
      }).subscribe(res => {
        response.data = RoleService.toListRole(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  getRolePermission(roleName): Promise<Response<Array<string>>> {
    let response: Response<Array<string>> = new Response<Array<string>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/User/GetRolePermission', {
        data: {'RoleName': roleName}
      }).subscribe(res => {
        response.data = RoleService.toCheckedPermission(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  editRolePermission(roleName, permissions: Array<Permission>): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/User/EditRolePermission', {
        data: RoleService.toEditPermissionParam(roleName, permissions)
      }).subscribe(res => {
        response.data = res['RoleName'];
        resolve(response);
      }, error => reject(error));
    });
  }

  addMerchantRole(roleName): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/User/AddMerchantRole', {
        data: roleName
      }).subscribe(res => {
        response.data = res['RoleName'];
        resolve(response);
      }, error => reject(error));
    });
  }

  deleteMerchantRole(roleName): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/User/DeleteMerchantRole', {
        data: {
          RoleName: roleName
        }
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }

  getAllPermission(): Promise<Response<Array<Permission>>> {
    let response: Response<Array<Permission>> = new Response<Array<Permission>>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/User/GetAllPermissions').subscribe(res => {
        response.data = RoleService.toAllPermission(res['Permissions']);
        resolve(response);
      }, error => reject(error));
    });
  }

  getAuthority(roleName): Promise<Response<Authority>> {
    let response: Response<Authority> = new Response<Authority>();
    return new Promise((resolve, reject) => {
      this.getRolePermission(roleName).then((res) => {
        response.data = RoleService.toAuthority(res.data);
        resolve(response);
      }).catch(error => reject(error));
    });
  }

  // 在auth之后调用 不报错
  // 在没有auth时候调用会给302
  // 因为/Boarding/Merchant/CreateMerchant给了401，然后会调用此函数
  getLoggedPermission(): Promise<Response<Array<string>>> {
    let response: Response<Array<string>> = new Response<Array<string>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/User/GetLoggedInUserPermission').subscribe(res => {
        response.data = RoleService.toCheckedPermission(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  getLoggedAuthority(): Promise<Response<Authority>> {
    let response: Response<Authority> = new Response<Authority>();
    return new Promise((resolve, reject) => {
      this.getLoggedPermission().then((res) => {
        response.data = RoleService.toAuthority(res.data);
        resolve(response);
      }).catch(error => reject(error));
    });
  }
}
