import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  
  orientation: string;
  subscription: Subscription;

  isPCagent = new Subject<boolean>();
  resizeEvent = () => {}

  constructor() { 
    this.resizeEvent = () => {
      const orientation = this.getOrientation();
      this.orientation$.next(orientation);
    }
    window.addEventListener('resize', this.resizeEvent);
  }

  getOrientationChanges(): Observable<string> {
    return this.orientation$.asObservable();
  }

  getPCAgentChanges(): Observable<boolean> {
    return this.isPCagent.asObservable();
  }

  created () {
    this.orientation = this.getOrientation();

    this.subscription = this.getOrientationChanges().subscribe(orientation => {
      this.orientation = orientation;
      this.isPCagent.next(this.isPC())
    });

  }

  destroyed () {
    this.subscription.unsubscribe();
    window.removeEventListener('resize', this.resizeEvent);
  }

  private orientation$ = new Subject<string>();
  getOrientation(): string {
    const orientation = window.matchMedia("(orientation: landscape)").matches ? 'landscape' :
    window.matchMedia("(orientation: portrait)").matches ? 'portrait' : '';
    
    return orientation;
  }
 


  // take a nap for a while
  static nap(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  isPC() {
    // 是否横屏
    const isLandscape = this.orientation === 'landscape'
    // const isLandscape = window.screen.height
    
    const maxWidth = window.screen.height > window.screen.width ? window.screen.height : window.screen.width
    const clientWidth = maxWidth >= 1080;
    return clientWidth && isLandscape;
  }
}
