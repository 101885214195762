export class Permission {
  /*  id: number;
    pid: number;
    name: string;
    code: string;
    router: string;
    icon: string;
    status: string;*/

  child: Array<Permission> = [];
  opened: boolean;
  disabled:boolean;

  permissionCode: string;
  permissionDisplayName: string;
  parentPermissionCode: string;
  description: string;
  checked: boolean;
  indeterminate: boolean;
  restrictFlag: boolean;
  dependentPermissionCodeList: Array<string>;

  constructor(options: {
    permissionCode?: string,
    permissionDisplayName?: string,
    parentPermissionCode?: string,
    description?: string,
    checked?: boolean,
    indeterminate?: boolean,
    restrictFlag?: boolean;
    disabled?: boolean;
    dependentPermissionCodeList?: Array<string>;

  } = {}) {
    this.permissionCode = options.permissionCode || '';
    this.permissionDisplayName = options.permissionDisplayName || '';
    this.parentPermissionCode = options.parentPermissionCode || '';
    this.description = options.description || '';
    this.checked = options.checked || false;
    this.opened = false;
    this.indeterminate = false;
    this.restrictFlag = options.restrictFlag || false;
    this.disabled = options.disabled || false;
    this.dependentPermissionCodeList = options.dependentPermissionCodeList || [];
  }
}
