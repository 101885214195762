import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-verification-box',
  templateUrl: './verification-box.component.html',
  styleUrls: ['./verification-box.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: VerificationBoxComponent,
    multi: true
  }]
})

export class VerificationBoxComponent implements OnInit, ControlValueAccessor {

  code: string;

  disabled: boolean;
  onChangeListener; // 改变值回调
  onTouchedListener; // 交互回调
  @Input() length: number = 6; // default

  constructor(private ele: ElementRef, private renderer2: Renderer2) {
  }

  ngOnInit() {
    this.appendInputs(); // 初始化，动态生成input
    this.focus();
  }

  appendInputs() {
    const _renderer2 = this.renderer2;
    let i = 0;
    while (i < this.length) {
      _renderer2.appendChild(this.ele.nativeElement, this.createInput());
      i++;
    }
  }

  createInput() {
    const _renderer2 = this.renderer2;
    const input = _renderer2.createElement('input');
    _renderer2.addClass(input, 'form-control');
    _renderer2.setAttribute(input, 'type', 'tel');
    _renderer2.setAttribute(input, 'maxlength', '1');
    _renderer2.listen(input, 'paste', this.onPaste);
    _renderer2.listen(input, 'input', this.onInput);
    _renderer2.listen(input, 'keydown', this.onKeydown);
    return input;
  }

  focus() {
    const inputs = this.ele.nativeElement.childNodes;
    inputs[0].focus();
  }

  onKeydown = (event) => {
    const e = event ? event : window.event;
    const target = e.currentTarget;
    const code = e.keyCode || e.which;
    if (code === 8) {
      if (!target.value) {  // 如果当前元素无值，聚焦上一个
        const prev = target.previousElementSibling; // 获取前一个元素
        if (prev) {
          prev.focus();
        }
      }
    } else if (e.key.length === 1) {
      if (e.key !== target.value) {
        target.value = '';  // 当前元素已有值，先清空
      }
    }
  };

  onPaste = (event: ClipboardEvent) => {
    const clipboardData = event.clipboardData || (window as any).clipboardData,
        pastedText = clipboardData.getData('text');

    if (isNaN(Number(pastedText))) {
      return;
    }

    const inputs = this.ele.nativeElement.childNodes,
        index = $(event.currentTarget).index();

    pastedText.split('').forEach((text, i) => {
      if (index + i < this.length) {
        inputs[index + i].value = text;
      }
    });

    let length = pastedText.length + index;
    if (length > this.length) {
      length = this.length;
    }
    inputs[length - 1].focus();
  };

  onInput = (event) => {
    const target = event.currentTarget;
    target.value = target.value.replace(/[^\d]/g, '');
    let val = '';
    this.ele.nativeElement.childNodes.forEach((node, i) => {
      if (node.value) {
        val += node.value;
      }
    });

    if (target.inputType !== 'insertFromPaste' && target.value) {
      const next = target.nextElementSibling; // 获取下一个元素
      if (next) {
        next.focus();
      }
    }

    this._valueChange(val);
  };

  _valueChange(val) {
    this.onChangeListener(val); // 告诉form，你的表单值改变成了payload
    this.onTouchedListener(); // 告诉form，你的表单有交互发生
  }

  /*ngModel 继承 ControlValueAccessor*/
  writeValue(val: any): void {
    if (!val) {
      return;
    }
    if (val.length > this.length) {
      this._valueChange(val.substr(0, this.length));  // 传入值大于设定长度，截取返回
    }
    this.ele.nativeElement.childNodes.forEach((node, i) => {
      if (val[i]) {
        node.value = val[i];
      }
    });
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }

}
