import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit {

  @Input() choice: { begin: Date, end: Date };
  @Input() searchText:string = '';
  @Input() searchName:string = '';
  @Input() isShow:boolean = true;

  @Output() searchEventEmit = new EventEmitter();
  @Output() clearEventEmit = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  searchEvent():void {
    this.searchEventEmit.emit({
    choice: this.choice, searchText: this.searchText, searchName: this.searchName
    });
  }

  clearEvent():void {
    this.searchName = '';
    this.searchText = '';
    this.choice={
        begin: new Date(), end: new Date()
    };
    this.clearEventEmit.emit({});
  }

}
