import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


import { Field } from '../../../models/field.interface';
import { SettingField } from '../../../models/settingConfig';


@Component({
  selector: 'app-form-checkbox-group',
  template: `
  <div class="form-group row" [formGroup]="group">
  <label class="up-form-label" [class.required]="config.required === 1">{{ config.displayName}}</label>
  <div class="up-form-control">
    <div class="" [formArrayName]="config.fieldName" [validators]="group.controls[config.fieldName]?.errors" [validators_submitted]="submitted">
    <label class="mr-4" *ngFor="let check of checks; let i = index" >
    <input type="checkbox"   [checked]="check.checked"  (change)="_valueChange($event.target.checked, check)"
      [disabled]="checkformArray.disabled"/> {{check.key}}
    </label>
    </div>
  </div>
  `,
  styles: [' .up-form-label {flex: 1;max-width: 100%;text-align: left !important;overflow: hidden;text-overflow:ellipsis; white-space: nowrap;}'],
})
export class FormCheckboxGroupComponent implements Field, OnInit {
  config: SettingField;
  group: FormGroup;

  checkformArray: FormArray;

  checks: Array<{ key: any, val: any, checked: boolean }> = [];
  submitted: boolean;


  ngOnInit() {
    this.checks = JSON.parse(JSON.stringify(this.config.checkboxList));
    this.checkformArray = this.group.get(this.config.fieldName) as FormArray;
  }
  _valueChange(isChecked: boolean, check) {
    if (isChecked) {
      let index = this.checkformArray.controls.findIndex(x => x.value === check.val);
      if (index === -1) {
        this.checkformArray.push(new FormControl(check.val));
      }

    } else {
      let index = this.checkformArray.controls.findIndex(x => x.value === check.val);
      this.checkformArray.removeAt(index);
    }
  }
 
}
