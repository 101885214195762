import {Component, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ForgotService} from '../../services/login.service';
import {LoadingService} from 'src/app/utils/loading.service';
import {TransactionService} from '../../services/transaction.service';
import {DialogService} from '../../utils/dialog.service';
import {Modal} from '../../utils/modal.service';

@Component({
  selector: 'app-anony-receipt',
  templateUrl: './anony-receipt.component.html',
  host: {class: 'up-login-container'},
  styleUrls: ['./anony-receipt.component.scss']
})
export class AnonyReceiptComponent implements OnInit {

  receiptIMG: string;
  msg: string;
  show: boolean = false;
  modal: Modal;
  traceId: string;

  captchaImg: string;
  token: string = '';
  receiptForm: FormGroup;
  submitted: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private transactionService: TransactionService, private dialog: DialogService, private loading: LoadingService, private forgotService: ForgotService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    let traceId = this.activatedRoute.snapshot.params['traceId'];
    this.traceId = traceId;
    this.getAnonyReceipt();
    this.initForm();
    this.getCaptcha();
  }

  getAnonyReceipt(){
    this.transactionService.getAnonyReceipt(this.traceId).then((res) => {
      this.receiptIMG = res.data;
    }).catch(error => {
      this.msg = this.dialog.error_msg(error);
    }).finally(() => {
      this.show = true;
    });
  }

  initForm() {
    this.receiptForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
          captchaCode: ['', Validators.required]
        }
    );
  }

  get f() {
    return this.receiptForm.controls;
  }

  email = {
    showModal: (template: TemplateRef<any>) => {
      this.submitted = false;
      this.modal = this.dialog.modal(template, 'RECEIPT.SEND_EMAIL');
      this.modal.show();
    },
    hideModal: () => {
      this.modal.hide();
    }
  };

  sendAnonyReceipt() {
    this.submitted = true;
    if (this.receiptForm.invalid) return;
    this.modal.hide();
    this.loading.show();
    this.transactionService.sendAnonyReceipt2(this.traceId, this.f.email.value, this.f.captchaCode.value, this.token).then(() => {
      this.dialog.success('RECEIPT.SENT_AND_CHECK');
      this.initForm();
    })
    .catch(res => {
      this.dialog.error(res);     
    })
    .finally(() => {
      this.getCaptcha();
      this.loading.hide();
    });
  }

  download() {
    const imgUrl = this.receiptIMG;
    let name = 'receipt';
    
    if (window.navigator.msSaveOrOpenBlob) {
      let bstr = atob(imgUrl.split(',')[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let blob = new Blob([u8arr]);
      window.navigator.msSaveOrOpenBlob(blob, name + '.' + 'png');
    } else {
      let a = document.createElement('a');
      a.href = imgUrl;
      a.setAttribute('download', name);
      a.click();
    }
  }

  getCaptcha() {
    this.loading.show();
    this.forgotService.getCaptcha().then((res) => {
      this.f.captchaCode.setValue('');
      this.captchaImg = res.data.captchaImg;
      this.updateToken(res.data.captchaToken);
    }).catch((res) => {
      this.dialog.error(res);
    }).finally(() => this.loading.hide());
  }

  updateToken(token) {
    if (token) {
      this.token = token;
    }
  }
}
