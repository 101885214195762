import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,ElementRef, OnDestroy} from '@angular/core';
import { CommonObj } from 'src/app/models/common';
import { GeneralService } from 'src/app/utils/general.service';

@Component({
  selector: 'app-mulu-select',
  templateUrl: './mult-select.component.html',
  styleUrls: ['./mult-select.component.scss']
})
export class MultSelectComponent implements OnInit, OnDestroy {

    @Input() selectList: Array<CommonObj> = [];
    @Input() selectValue: Array<CommonObj> = [];


    @Output() selectChange = new EventEmitter();

    isShowFilter = false;
    Value = [];
    valueText = '';
  constructor(private ele: ElementRef, private generalService: GeneralService) { }

  ngOnInit() {
    this.Value = this.selectValue;
    this.selectList = this.selectList.map(item => {
        const index = this.Value.findIndex(val => val === item.value);
        if(index !== -1) {
           item.selected = true;
        }
        return {...item};
    })
    this.valueText = this.Value.join(',')
  }

  selectItem(item) {
    const index = this.Value.findIndex(val => val === item.value);
    if(index === -1) {
        item.selected = true;
        this.Value.push(item.value);
        this.valueText = this.Value.join(',');
        this.selectChange.emit(this.Value);
    } else {
        item.selected = false;
        this.Value.splice(index, 1);
        this.valueText = this.Value.join(',');
        this.selectChange.emit(this.Value);
    }
  };

  ngOnDestroy(): void {
      
  }
}
