import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../../services/login.service';
import {DialogService} from '../../utils/dialog.service';
import {LoadingService} from '../../utils/loading.service';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {FindFirstPage} from '../../constants/menu';
import {ValidatorsService} from '../../validtors/validators.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  host: {class: 'up-login-container'},
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;

  loginForm: FormGroup;
  submitted = false;

  numTimes: number = 0;

  emailStatus = false;
  pswStatus = false;
  error_msg
  constructor(private router: Router, private loading: LoadingService, private validatorsService: ValidatorsService,
              private loginService: LoginService, private dialog: DialogService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.numTimes == 0;
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
      password: ['', Validators.required]
    });
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) return;
    this.loading.show();
    this.numTimes++;
    this.loginService.login(this.f.email.value, this.f.password.value).then((res) => {
      console.log(res)
      if (res.data.isTwoFactor) {
        this.router.navigate(['/twofactorlogin'], {queryParams: {email: this.f.email.value}});
      } else {
        let router = FindFirstPage(res.data.authority);
        this.loginService.checkingUser({email: this.f.email.value}).then(check => {
        
          if(check['data'].count === 1) {
            if(check['data'].type === 0){
              this.router.navigate(['/' + router]);
            } else {
              check['data'].type === 1 ? 
              this.router.navigate(['/number-register'], {queryParams: {firstTime: true, route: router}}) :
              this.router.navigate(['/webhook'], {queryParams: {firstTime: true, route: router}})
            }
          }
          if(check['data'].count === 2) {
            this.router.navigate(['/' + router]);
          }
        }).catch(error => {
          this.dialog.error(error)
          this.loginService.logoutApi();
          this.loginService.logout();
          // console.log(error, 'error')
        })
      }
    }).catch((res) => {
      let alert = this.dialog.error(res, 'Login Failed');
      // const resetCode = res.resultCode === '000001' || res.ResultCode === '000001'
      if (alert && res.resultCode === '000001') alert.onHidden.subscribe(() => {
        this.router.navigate(['/reset-password'], {queryParams: {email: this.f.email.value}})
      }
      );
      
      if (alert && this.numTimes == 5) alert.onHidden.subscribe(() => this.router.navigate(['/forgot-password']));
      
    }).finally(() => this.loading.hide());
  }

  checkEmailValidate () {
    // console.log(this.loginForm.invalid)
    // // this.submitted = true;
    // this.loginForm.invalid;
    this.emailStatus = this.loginForm.controls['email'].status !== 'VALID'
  };

  checkPassWordValidate() {
    this.pswStatus = this.loginForm.controls['password'].status !== 'VALID'
  }

  get f() {
    return this.loginForm.controls;
  }


}
