import {Injectable} from '@angular/core';
import {UrlMap, TitleMap} from '../constants/custom-map';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  static prefix: string;

  constructor() {
  }

  static setUrlPrefix(prefix: string) {
    this.prefix = prefix;
  }

  static getUrlPrefix(): string {
    return this.prefix;
  }

  static transTypeList(transTypeList) {
    if (CustomService.getUrlPrefix() === 'boa') {
      let void_index = transTypeList.indexOf('VOID');
      if (void_index !== -1) transTypeList.splice(void_index, 1);
    }
    return transTypeList;
  }

  static title() {
    let title = '';
    let prefix = CustomService.getUrlPrefix();
    if (prefix && TitleMap[prefix]) title = TitleMap[prefix] + ' ';
    return title;
  }
}
