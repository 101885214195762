import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {MerchantService} from '../../services/merchant.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  resellerNum: string;

  constructor(private merchantService: MerchantService, public authService: AuthService) {
  }

  ngOnInit() {
    this.getResellernum();
  }

  getResellernum() {
    this.merchantService.getResellerNum().then(res => {  
      this.resellerNum = res.data;  
    });
  }
}
