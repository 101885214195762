import {ModifierGroup} from './modifierGroup';

export class Item {
  guid: string;
  itemName: string;
  categoryGuid: string;
  trackInventory: boolean;
  stockQuantity: number;
  enable: boolean;
  displayInMenu: boolean;
  sku: string;
  displayOrder: number;
  itemDesc: string;
  price: number;
  priceExclTax: number;
  taxCategoryGuid: string;
  taxExempt: boolean;
  isModifier: boolean;
  modifiers: Array<Item>;
  modifierGuids: Array<string>;
  modifierGroupGuids: Array<string>;
  createdDate: string;
  lastUpdatedDate: string;
  // createdUTC: Date;
  // lastUpdatedUTC: Date;
  createdBy: string;
  lastUpdatedBy: string;
  checked: boolean;
  picture: string;
  taxCategoryName: string;
  categoryName: string;
  category: string;
  tax: string;

  constructor(options: {
    guid?: string,
    itemName?: string,
    categoryGuid?: string,
    trackInventory?: boolean,
    stockQuantity?: number,
    enable?: boolean,
    displayInMenu?: boolean,
    sku?: string,
    displayOrder?: number,
    itemDesc?: string,
    price?: number,
    priceExclTax?: number,
    taxCategoryGuid?: string,
    taxExempt?: boolean,
    isModifier?: boolean,
    modifiers?: Array<Item>,
    modifierGuids?: Array<string>,
    modifierGroups?: Array<ModifierGroup>,
    modifierGroupGuids?: Array<string>,
    createdDate?: string,
    lastUpdatedDate?: string,
    createdBy?: string,
    lastUpdatedBy?: string,
    checked?: boolean,
    picture?: string,
    taxCategoryName?: string,
    tax?: string,
    category?: string,
    categoryName?: string
  } = {}) {
    this.guid = options.guid || '';
    this.itemName = options.itemName || '';
    this.categoryGuid = options.categoryGuid || '';
    this.trackInventory = options.trackInventory || false;
    this.stockQuantity = options.stockQuantity || 0;
    this.enable = options.enable || false;
    this.displayInMenu = options.displayInMenu || false;
    this.sku = options.sku || '';
    this.displayOrder = options.displayOrder || null;
    this.itemDesc = options.itemDesc || '';
    this.price = options.price || null;
    this.priceExclTax = options.priceExclTax || null;
    this.taxCategoryGuid = options.taxCategoryGuid || '';
    this.taxExempt = options.taxExempt || false;
    this.isModifier = options.isModifier || false;
    this.modifiers = options.modifiers || [];
    this.modifierGuids = options.modifierGuids || [];
    this.modifierGroupGuids = options.modifierGroupGuids || [];
    this.createdDate = options.createdDate || '';
    this.lastUpdatedDate = options.lastUpdatedDate || null;
    this.createdBy = options.createdBy || '';
    this.lastUpdatedBy = options.lastUpdatedBy || '';
    this.checked = options.checked || false;
    this.picture = options.picture || '';
    this.taxCategoryName = options.taxCategoryName || '';
    this.tax = options.tax || '';
    this.category = options.category || '';
    this.categoryName = options.categoryName || '';
  }
}
