import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SignUpService } from "../../services/sign-up.service";
import { LoginService } from "../../services/login.service";
import { DialogService } from "../../utils/dialog.service";
import { LoadingService } from "../../utils/loading.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { FindFirstPage } from "../../constants/menu";
import { ValidatorsService } from "../../validtors/validators.service";
// import { GeneralService } from "src/app/utils/general.service";
// import { Component, OnInit } from '@angular/core';
// import {Router} from '@angular/router';
// import {FormGroup, Validators, FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-sign-up',
  host: {class: 'up-login-container'},
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  email: string;
  firstName: string;
  lasttName: string;
  merchantName: string;

  submitted = false;
  signUpForm: FormGroup;

  error_msg: string;

  constructor(
    private router: Router,
    private loading: LoadingService,
    private validatorsService: ValidatorsService,
    private loginService: LoginService,
    private dialog: DialogService,
    private formBuilder: FormBuilder,
    private signUpService: SignUpService,
  ) { }

  ngOnInit() {
    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
      firstName: ['', [Validators.required, Validators.maxLength(32)]],
      lastName: ['', [Validators.required,Validators.maxLength(32)]],
      storeName: ['', [Validators.required, Validators.maxLength(32)]]
      // password: ['pwd', Validators.required]
    });
  }

  async signUp() {
    this.submitted = true;
    if (this.signUpForm.invalid) return;


    // .then((res) => {
    //   console.log("then RES: " + JSON.stringify(res, null, 4));

    //   // TODO:
    //   setTimeout(() => {
    //     this.loading.hide();
    //     this.dialog.notification('Sign up succeed', 'Now go back to login page.', () => {
    //       this.router.navigate(['/login']);
    //     });
    //   }, 2000)
    // })

    //   this.loginService
    //     .login(this.f.email.value, this.f.password.value)
    //     .then((res) => {
    //       console.log("then RES: " + JSON.stringify(res, null, 4));

    //       this.loading.hide();

    //       if (res.data.isTwoFactor) {
    //         this.router.navigate(["/twofactorlogin"], {
    //           queryParams: { email: this.f.email.value },
    //         });
    //       } else {
    //         let router = FindFirstPage(res.data.authority);
    //         this.router.navigate(["/" + router]);
    //       }
    //     })
    //     .catch((res) => {
    //       console.log("catch RES: " + JSON.stringify(res, null, 4));
    //       let alert = this.dialog.error(res);
    //       if (alert && res.resultCode === "000001")
    //         alert.onHidden.subscribe(() =>
    //           this.router.navigate(["/reset-password"], {
    //             queryParams: { email: this.f.email.value },
    //           })
    //         );

    //       if (alert && this.numTimes == 5)
    //         alert.onHidden.subscribe(() =>
    //           this.router.navigate(["/forgot-password"])
    //         );
    //       // this.error_msg = this.dialog.error_msg(res);

    //     });
  }
  titleList = [
    {
      title: 'Get start quickly',
      detail: 'Integrate with developer-friendly APIs or choose low-code or pre-build solutions.'
    },
    {
      title: 'Support any business model',
      detail: 'E-commerce, subscription, SaaS platforms, marketplaces, and more—all within a unified platform.'
    },
    {
      title: 'Join millions of businesses',
      detail: 'ARGO LABS is trusted by ambitious startups and enterprises of every size.'
    }
  ]

  signInfoList = [
    {
      field: 'email',
      placeHolder: 'LOGIN.ENTER_EMAIL',
      innerText: 'USER.EMAIL',
      type: 'text',
      status: false
    },
    {
      field: 'firstName',
      placeHolder: 'LOGIN.ENTER_FIRSTNAME',
      innerText: 'USER.FIRST_NAME',
      type: 'text',
      status: false
    },
    {
      field: 'lastName',
      placeHolder: 'LOGIN.ENTER_LASTNAME',
      innerText: 'USER.LAST_NAME',
      type: 'text',
      status: false
    },
    {
      field: 'storeName',
      placeHolder: 'Enter business Name',
      innerText: 'Business Name',
      type: 'text',
      status: false
    },
    
  ]

  selections = [{key: 'I am a merchant', val: 'Merchant'},
   {key:'I am a developer', val: 'Developer'}]
  userRole = 'Merchant'

  // onRoleChange(key) {
  //   this.userRole = key;
  // }

  async signUpClick() {
    // validate every item
    this.signInfoList.forEach(item => {
      this.checkFormValidate(item.field)
    })
    if(this.signUpForm.invalid) {
      return
    }

    this.loading.show();

    try {
      console.log(this.f)
      const data = {
        'merchantName': this.f.storeName.value,
        'userEmail': this.f.email.value,
        'firstName': this.f.firstName.value,
        'lastName': this.f.lastName.value,
        userType: this.userRole == 'Merchant'? 1: 2
      }
      //TODO validate user email
      const response = await this.signUpService.signUp(data)

      if (response.resultCode == '000000') {
        this.router.navigate(['/validate-email'], {queryParams : { email: this.f.email.value}})
        // this.dialog.notification('Register Success!', response.resultMsg, () => {
        //   // this.router.navigate(['/login']);
        //   this.router.navigate(['/validate-email'], {queryParams : { email: this.f.email.value}})
        // });
      }
    } catch (e) {
      console.log(e)
      this.dialog.notification('Register Error!', e.resultMsg || 'Unknown Error', () => { });
    } finally {
      this.loading.hide();
    }
  }


  checkFormValidate (field: string | number) {

    const infoItem = this.signInfoList.find(item => item.field === field)
    infoItem.status = this.signUpForm.controls[field].status !== 'VALID'
  };

  get f() {
    return this.signUpForm.controls;
  }

}
