import {Injectable} from '@angular/core';
import {Auth} from '../models/auth';
import {User} from '../models/user';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Authority} from '../models/authority';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpService, private cookieService: CookieService) {
  }

  setSession = (key, val) => {
    this.cookieService.set(key, JSON.stringify(val), null, '/');
  };

  getSession = (key) => {
    let val = this.cookieService.get(key);
    return val ? JSON.parse(val) : null;
  };

  rmSession = (key) => {
    this.cookieService.delete(key, '/');
  };

  getAuth(): Auth {
    return this.getSession('UPTTINF') || new Auth();
  }

  setAuthToken(token: string) {
    this.cookieService.set(
      'Auth_Token',          // name
      token,       // value
    );
  }

  getAuthToken(): string {
    return this.getSession('Auth_Token') || "";
  }

  setRefreshTokenh(token: string) {
    this.cookieService.set(
      'Refresh_Token',          // name
      token,       // value
    );
  }

  getRefreshTokenh(): string {
    return this.getSession('Refresh_Token') || "";
  }

  setAuth(auth: Auth) {
    this.setSession('UPTTINF', auth);
  }

  getUserType() {
    return this.getSession('userType');
  }

  setUserType(type: number) {
    this.setSession('userType', type);
  }

  rmUserType() {
    this.rmSession('userType');
  }

  rmAuth() {
    this.rmSession('UPTTINF');
    this.rmSession('auth');
  }

  getSalt(): Auth {
    return this.getSession('salt') || '';
  }

  setSalt(auth: Auth) {
    this.setSession('salt', auth);
  }

  rmSalt() {
    this.rmSession('salt');
  }

  setLoginFlag(): void {
    this.setSession('merLogin', true);
  }

  getLoginFlag(): string {
    return this.getSession('merLogin');
  }

  rmLoginFlag(): void {
    this.rmSession('merLogin');
  }

  getProfile(): User {
    return this.getSession('profile') || new User();
  }

  setProfile(user) {
    let profile = {
      userName: user.nickname ? user.nickname : `${user.given_name} ${user.family_name}`,
      firstName: user.given_name,
      lastName: user.family_name,
      email: user.email
    };
    this.setSession('profile', profile);
    // let role = user.role;
    // if (role) this.setSession('role', role);
  }

  rmProfile() {
    this.rmSession('profile');
    this.rmSession('role');
    this.rmSession('merExpiredTime');
    this.rmSalt();
    this.rmLoginFlag();
  }

  getAuthority(): Authority {
    let authorityByStorage: any = this.getSession('authority') || [];
    let flag = authorityByStorage instanceof Array;
    if (!flag) { // 判断cookie中类型，如果不为Array则为旧缓存方案，需要兼容，重新做一次存取
      this.setAuthority(authorityByStorage);
      authorityByStorage = this.getSession('authority') || [];
    }

    let authority: Authority = new Authority();
    for (let item of authorityByStorage) {
      authority[item] = true;
    }
    return authority;
  }

  setAuthority(authority: Authority) {
    let checkedList: Array<string> = [];
    for (let item in authority) {
      if (authority.hasOwnProperty(item) && authority[item]) checkedList.push(item);
    }
    // this.setSession('authority', authority);   旧缓存方式，getAuthority需要特殊解决方案
    this.setSession('authority', checkedList);
  }

  setIdTokenInfo(store_id ,merchant_id) {
    // const {signature, payload, header} = idToken;

    // const {store_id, merchant_id,picture} = payload;
    //aud email email_verified exp iss family_name given_name ...

    this.setSession('store_id', store_id);
    this.setSession('merchant_id', merchant_id);

  }

  rmAuthority() {
    this.rmSession('authority');
  }

  refreshToken(): Observable<Auth> {
    let auth = this.getAuth();
    // /Merchant/Login/RefreshToken
    return this.http.post('/AIphone/v1/Login/RefreshToken', {
      data: {
        'AccessKeyID': auth.accessKeyID,
        'RefreshToken': auth.refreshToken
      }
    }).pipe(
        map(res => {
          auth.accessToken = res['AccessToken'];
          // auth.accessToken = res['AccessToken'];
          // auth.refreshToken = res['RefreshToken'];
          this.setLoginFlag();
          return auth;
        })
    );
  }

}
