export class Authority {
  dashboard: boolean;

  merchant: boolean;
  merchant_view_all: boolean;
  merchant_view_detail: boolean;
  merchant_edit: boolean;
  merchant_terminal_view: boolean;
  merchant_processor_view: boolean;

  receipt: boolean;
  merchant_receipt_view: boolean;
  merchant_receipt_edit: boolean;
  merchant_custom_receipt_view: boolean;
  merchant_custom_receipt_edit: boolean;
  payment_receipt: boolean;

  transaction: boolean;
  transaction_view_all: boolean;
  transaction_view_refund: boolean;
  transaction_view_void: boolean;
  transaction_all: boolean;
  transaction_download_transaction: boolean;

  role: boolean;
  role_view_all: boolean;
  role_add: boolean;
  role_edit: boolean;
  role_delete: boolean;

  user: boolean;
  user_view_all: boolean;
  user_view_detail: boolean;
  user_add: boolean;
  user_edit: boolean;
  user_casual_email_edit: boolean;
  user_reset_password: boolean;
  user_reset_passcode: boolean;

  report: boolean;
  report_download_transaction: boolean;
  report_employee: boolean;
  report_transaction: boolean;
  report_transaction_all: boolean;

  inventory: boolean;
  
  item: boolean;
  item_view: boolean;
  item_add: boolean;
  item_edit: boolean;
  item_delete: boolean;
  item_modifier: boolean;
  category: boolean;
  category_view: boolean;
  category_add: boolean;
  category_edit: boolean;
  category_delete: boolean;

  stock: boolean;
  stock_management_view: boolean;
  stock_management_manage: boolean;
  modifier_group: boolean;
  modifier_group_view: boolean;
  modifier_group_add: boolean;
  modifier_group_edit: boolean;
  modifier_group_delete: boolean;

  tax: boolean;
  tax_view: boolean;
  tax_add: boolean;
  tax_edit: boolean;
  tax_delete: boolean;
  tax_override_taxcategory: boolean;
  tax_category: boolean;
  tax_category_view: boolean;
  tax_category_add: boolean;
  tax_category_edit: boolean;
  tax_category_delete: boolean;

  setting_config: boolean;
  setting_config_view: boolean;
  setting_config_edit: boolean;

  order: boolean;
  order_view: boolean
}
