import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: 'input[type=phone]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneFormatDirective),
      multi: true
    }
  ]
})

export class PhoneFormatDirective implements ControlValueAccessor {

  @HostListener('input', ['$event.target.value']) onChange = (_: any) => {
  };

  @HostListener('blur', []) onTouched = () => {
  };

  get element() {
    return this.elementRef.nativeElement;
  }

  constructor(private elementRef: ElementRef, private _renderer: Renderer2) {
  }

  writeValue(value: string): void {
    this._renderer.setProperty(this.element, 'value', this.formatPhone(value));
  }

  registerOnChange(fn: any): void {
    this.onChange = (value: string) => {
      const phone = this.formatPhone(value);
      this._renderer.setProperty(this.element, 'value', phone);
      fn(phone);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this.element, 'disabled', isDisabled);
  }

  formatPhone(phone: string) {
    if (!phone) return '';
    let formatPhone: string = phone.replace(/[^0-9]/ig, '');

    if (phone.startsWith('+')) {
      if (formatPhone.startsWith('86') && formatPhone.length > 2)
        return `+${formatPhone.substr(0, 2)} ${this.cnPhoneFormat(formatPhone.substring(2))}`;

      else if ((formatPhone.startsWith('91') || formatPhone.startsWith('44')) && formatPhone.length > 2)
        return `+${formatPhone.substr(0, 2)} ${formatPhone.substring(2)}`;

      else if (formatPhone.startsWith('852') && formatPhone.length > 3)
        return `+${formatPhone.substr(0, 3)} ${formatPhone.substring(3)}`;

      else if (formatPhone.startsWith('1') && formatPhone.length > 1)
        return `+1 ${this.usPhoneFormat(formatPhone.substring(1))}`;

      else return `+${formatPhone}`;
    } else {
      return this.usPhoneFormat(formatPhone);
    }
  }

  usPhoneFormat = (phone: string) => {
    let format: string = ``; // `(${phone.substr(0, 3)}`;
    if (phone.length)
      format = `(${phone.substr(0, 3)}`;
    if (phone.length > 3) {
      format += `)${phone.substr(3, 3)}`;
    }
    if (phone.length > 6) {
      format += `-${phone.substr(6, 4)}`;
    }
    return format;
  };

  cnPhoneFormat = (phone: string) => {
    return phone.substr(0, 11);
  };

}
