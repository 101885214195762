import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Router} from '@angular/router';
import {EncryptService} from '../utils/encrypt.service';
import {Response} from '../models/response';
import {Auth} from '../models/auth';
import {DateService} from '../utils/date.service';
import {AuthService} from './auth.service';
import {ProfileService} from './profile.service';
import {RoleService} from './role.service';
import {Authority} from '../models/authority';
import {Constant} from '../constants/constant';
import {DialogService} from '../utils/dialog.service';
import {UrlMap} from '../constants/custom-map';
import { CookieService } from 'ngx-cookie-service';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpService, private authService: AuthService, private router: Router, private dialog: DialogService,
              private profileService: ProfileService, private roleService: RoleService, private cookieService: CookieService, private orderService: OrderService) {
  }

  static toAuthModel(res, key): Auth {
    let merchantList: Array<string> = [];
    let merchantLogin: string = '';
    let primaryMerchantName: string = '';
    for (let mer of res['MerchantNameList']) {
      merchantList.push(mer.MerchantName);
      if (mer[key]) merchantLogin = mer.MerchantName;
      if (mer['IsPrimary']) primaryMerchantName = mer.MerchantName;
    }
    return new Auth({
      accessKeyID: res['AccessKeyID'],
      uptmpasid: res['AccessKeySecret'],
      accessToken: res['AccessToken'],
      refreshToken: res['RefreshToken'],
      tokenType: res['TokenType'],
      merchantUserKey: res['MerchantUserKey'] ? res['MerchantUserKey'].toString() : '',
      expiresIn: res['ExpiresIn'],
      issued: res['Issued'],
      expires: res['Expires'],
      isTwoFactor: !Boolean(res['RefreshToken']),
      lastLogon: DateService.getDateAndTimeString(DateService.utcToLocal(res['LastLogon'])),
      // firstName: res['FirstName'],
      // lastName: res['LastName'],
      isPrimaryUser: res['IsPrimaryUser'],
      merchantNameList: merchantList,
      merchantName: merchantLogin,
      primaryMerchantName,
      authToken: res['AuthToken'],
    });

  }

  async logout() {
    clearInterval(this.orderService.timeFun);
    await this.logoutApi();
    this.rmSessionAndRedirect();
 }

  rmSessionAndRedirect() {
    this.authService.rmSession('store_id');
    this.authService.rmSession('merchant_id');
    this.authService.rmSession('signature');
    this.authService.rmSession('idToken');
    this.authService.rmSession('merLogin');
    this.authService.rmSession('merExpiredTime')

    window.location.href = 'https://auth.argolabs.ai/v2/logout?client_id=8noWFakMHiQp4V1urtmwTxqSNJHL9oMl&&returnTo=https://auth-server-main.up.railway.app/login';

 
  }

  logoutApi(): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.get('/railwayAuth0/logout_session').subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  logoutAuth(): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.get('/authArgolabs/logout/', {
        restful: {client_id: '8noWFakMHiQp4V1urtmwTxqSNJHL9oMl'}
      }).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }


  getSalt(email): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Login/GetForeEndSalt', {
        restful: {'Email': email}
      }).subscribe(res => {
        if(res['ForeEndSalt'])this.authService.setSalt(res['ForeEndSalt']);
        response.data = res['ForeEndSalt'];
        resolve(response);
      }, error => reject(error));
    });
  }

  getAuth(email, password, salt): Promise<Response<Auth>> {
    let response: Response<Auth> = new Response<Auth>();
    return new Promise((resolve, reject) => {
      this.http.post('/AIphone/v1/Login/GetAuth', {
      // this.http.post('/Merchant/Login/GetAuth', {
        data: {'Email': email, 'Password': EncryptService.salt(password, salt)}
      }).subscribe(res => {
        response.data = LoginService.toAuthModel(res, 'IsPrimary');

        resolve(response);
      }, error => {
        if(error.resultCode === '000001'){
          error.data = LoginService.toAuthModel(error.metadata, 'IsPrimary');
          this.authService.setAuth(error.data);
        }
        reject(error)
      });
    });
  }

  sendCode(): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/TwoFactorAuth/SendVerificationCode').subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }

  twoFactorAuth(code): Promise<Response<{ authority: Authority }>> {
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/TwoFactorAuth/Verify', {
        data: {'VerificationCode': code}
      }).subscribe(res_auth => {
        this.authService.setLoginFlag();
        this.saveInfo().then((res) => {
          resolve(res);
        }).catch(error => reject(error));
      }, error => {
        if(error.resultCode === '000001'){
          let auth = this.authService.getAuth();
          // auth.accessToken = error.metadata['AccessToken'];
          // auth.refreshToken = error.metadata['RefreshToken'];
          // this.authService.setAuth(auth);
          this.authService.setAuth(auth);
          this.authService.setLoginFlag();
        }
        reject(error);
      });
    });
  }


  // save profile and authority
  saveInfo(): Promise<Response<{ authority: Authority }>> {
    let response: Response<{ authority: Authority }> = new Response<{ authority: Authority }>();
    return new Promise((resolve, reject) => {
      // this.authService.setAuth(auth);
      this.profileService.getProfile().then((res_user) => {  // 获取当前profile
        let user = res_user.data;
        this.authService.setProfile(user);
        this.roleService.getLoggedAuthority().then((res_authority) => { // 获取当前权限
          this.authService.setAuthority(res_authority.data);
          response.data = {authority: res_authority.data};
          resolve(response);
        }).catch(error => reject(error));
      }).catch(error => reject(error));
    });
  }

  login(email, password): Promise<Response<any>> {
    this.authService.rmAuth();
    return new Promise((resolve, reject) => {
      this.getSalt(email).then((res_salt) => {  // 获取盐值
        this.getAuth(email, password, res_salt.data).then((res_auth) => {  // 获取auth
          this.authService.setLoginFlag();
          this.authService.setAuth(res_auth.data);
          this.authService.setAuthToken(res_auth.data.accessToken);
          this.authService.setRefreshTokenh(res_auth.data.refreshToken);

          if (!res_auth.data.isTwoFactor) {
            this.saveInfo().then((res) => {
              //! session has everything
              const val = this.cookieService.getAll()
              console.log('this.cookieService.getAll(): ' + JSON.stringify(val, null, 4));
              resolve(res);
            }).catch(error => reject(error));
          }else{
            resolve(res_auth);
          }
        }).catch(error => reject(error));
      }).catch(error => reject(error));
    });
  }

  logoutAlert() {
    this.dialog.confirm('LOGIN.LOGOUT', 'LOGIN.SURE_LOGOUT',  () => {
      
      this.logout();
    });
  }

  updatePassword(newpwd, confirmpwd, salt): Promise<Response<Auth>> {
    let response: Response<Auth> = new Response<Auth>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/UpdatePassword/UpdatePassword', {
        data: {
          'Password': EncryptService.salt(newpwd, salt),
          'ConfirmPassword': EncryptService.salt(confirmpwd, salt)
        }
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }

  updateLogin(newpwd, confirmpwd, email): Promise<Response<any>> {
    return new Promise((resolve, reject) => {
      this.getSalt(email).then((res_salt) => {  // 获取盐值
        this.updatePassword(newpwd, confirmpwd, res_salt.data).then((res) => {  // 获取auth
          resolve(res);
          // this.authService.setAuth(res_auth.data);
          // if (res_auth.data.refreshToken) {
          //   this.saveInfo().then((res) => {
          //     resolve(res);
          //   }).catch(error => reject(error));
          // }else{
          //   resolve(res_auth);
          // }
        }).catch(error => reject(error));
      }).catch(error => reject(error));
    });
  }

  checkingUser(params) {
    let response: Response<any> = new Response<any>();
    return new Promise((resolve, reject) => {
      this.http.post('/AIphone/check/checkUserTracking/checkingUser', {
        data: params
      }).subscribe(res => {
        response.data = res;
        this.authService.setUserType(res.type);
        resolve(response);
      }, error => reject(error));
    });
  }

}


@Injectable({
  providedIn: 'root'
})
export class InitiateService {

  constructor(private http: HttpService) {
  }

  verify(token): Promise<Response<{ email: string, foreEndSalt: string, noticeBind: boolean, token: string }>> {
    let response: Response<{ email: string, foreEndSalt: string, noticeBind: boolean, token: string }> =
        new Response<{ email: string, foreEndSalt: string, noticeBind: boolean, token: string }>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Initiate/Verify', {
        restful: {'WelcomeToken': token}
      }).subscribe(res => {
        response.data = {
          email: res['Email'],
          foreEndSalt: res['ForeEndSalt'],
          noticeBind: res['NoticeBind'],
          token: res['InitiateToken']
        };
        resolve(response);
      }, error => reject(error));
    });
  }

  initiatePassword(data: { salt, newPwd, confirmPwd, token }): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Initiate/InitiatePassword', {
        data: {
          'InitiateToken': data.token,
          'NewPassword': EncryptService.salt(data.newPwd, data.salt),
          'ConfirmNewPassword': EncryptService.salt(data.confirmPwd, data.salt)
        }
      }).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  getConfiguredDomain(): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Initiate/GetConfiguredDomain').subscribe(res => {
        let result = res['DomainName'] ? res['DomainName'].toLocaleLowerCase() : '';
        // response.data = (UrlMap.indexOf(result) !== -1) ? result : UrlMap[0];
        response.data = UrlMap[0];
        resolve(response);
      }, error => {
        response.data = UrlMap[0];
        resolve(response);
      });
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class ResetService {

  constructor(private http: HttpService) {
  }

  verify(token): Promise<Response<{ foreEndSalt: string, resetType: string, passcodeLength: number, token: string }>> {
    let response: Response<{ foreEndSalt: string, resetType: string, passcodeLength: number, token: string }> =
        new Response<{ foreEndSalt: string, resetType: string, passcodeLength: number, token: string }>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Reset/Verify', {restful: {'ResetToken': token}}).subscribe(res => {
        response.data = {
          foreEndSalt: res['ForeEndSalt'],
          resetType: res['ResetType'],
          passcodeLength: res['MerchantPasscodeLength'] ? parseInt(res['MerchantPasscodeLength'], 10) : Constant.DEFAULT_PASSCODE_LENGTH,
          token: res['ResetType'] === 'Password' ? res['ResetPasswordToken'] :
              (res['ResetType'] === 'Passcode' ? res['ResetPasscodeToken'] : '')
        };
        resolve(response);
      }, error => reject(error));
    });
  }

  resetPassword(data: { newPwd, confirmPwd }, token, salt): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Reset/ResetPassword', {
        data: {
          'ResetPasswordToken': token,
          'NewPassword': EncryptService.salt(data.newPwd, salt),
          'ConfirmNewPassword': EncryptService.salt(data.confirmPwd, salt)
        }
      }).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  resetPasscode(data: { newPcd, confirmPcd }, token, salt): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Reset/ResetPasscode', {
        data: {
          'ResetPasscodeToken': token,
          'NewPasscode': EncryptService.salt(data.newPcd, salt),
          'ConfirmNewPasscode': EncryptService.salt(data.confirmPcd, salt)
        }
      }).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class ForgotService {

  constructor(private http: HttpService) {
  }

  sendForgotEmail(email): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Forgot/SendForgotEmail', {data: {'Email': email}}).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  sendForgotEmail2(email, imagetext, token): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Forgot/SendForgotEmail2', {
        data: {
          'Email': email,
          'CaptchaImageText': imagetext,
          'CaptchaToken': token
        }
      }).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  getCaptcha(): Promise<Response<{ captchaImg: string, captchaToken: string }>> {
    const response: Response<{ captchaImg: string, captchaToken: string }> = new Response<{ captchaImg: string, captchaToken: string }>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Captcha/GetCaptchaImage').subscribe(res => {
        response.data = {
          captchaImg : res['Image'] ? Constant.IMG_BASE64_PREFIX + res['Image'] : '',
          captchaToken: res['CaptchaToken']
        };
        resolve(response);
      }, error => reject(error));
    });
  }

}

