import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[table-hover-content]'
})
export class TableHoverDirective {
  constructor(private el: ElementRef) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    const td = this.el.nativeElement.closest('td');
    const hoverContent = this.el.nativeElement.querySelector('.hover-content-container');
    const tdRect = td.getBoundingClientRect();
    const tableContainerRect = td.closest('.table-responsive').getBoundingClientRect();
    const hoverContentRect = hoverContent.getBoundingClientRect();
    const spaceBelow = tableContainerRect.bottom - tdRect.bottom;
    const spaceTop = tdRect.top - tableContainerRect.top;
    const spaceRight = tableContainerRect.right - hoverContentRect.right;
    if((spaceBelow > hoverContent.offsetHeight) || (spaceTop < hoverContent.offsetHeight)) {
      hoverContent.style.top = '100%'
      hoverContent.style.bottom = 'auto' 
    } else {
      hoverContent.style.top = 'auto'
      hoverContent.style.bottom = '100%'
    }
    if(spaceRight < 0) {
      hoverContent.style.right = '5px'
      hoverContent.style.left = 'auto'
    }
  }
}
