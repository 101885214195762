import {Constant} from './constant';

export const Menu = [
  {
    name: 'Orders', router: '/orders', active: false, icon: 'assets/imgs/order.png', key: Constant.ALWAYS_SHOW, activeRouteList: ['/orders/orders-list','/orders/order-list/orders-detail']
  },
  {
    name: 'Calls', router: '/call', active: false, icon: 'assets/imgs/call.png', key: Constant.ALWAYS_SHOW, activeRouteList: ['/call/call','/call/detail']
  },
  {
    name: 'Phone', router: '/number-register', active: false, icon: 'assets/imgs/number.png', key: Constant.ALWAYS_SHOW,
    
  },
  {
    name: 'Menu Config', router: '/menu-config', active: false, icon: 'assets/imgs/menu.png', key: Constant.ALWAYS_SHOW,
    activeRouteList: [
      '/menu-config/menu',
      '/menu-config/categories',
      '/menu-config/modifier-group',
      '/menu-config/items',
      '/menu-config/modifier-items',
      '/menu-config/add-categories',
      '/menu-config/edit-categories',
      '/menu-config/add-modifier-item',
      '/menu-config/edit-modifier-item',
      '/menu-config/add-modifier-group',
      '/menu-config/edit-modifier-group',
      '/menu-config/add-item',
      '/menu-config/edit-item',]
  },
  {
    name: 'Support', router: '/support', active: false, icon: 'assets/imgs/support.png', key: Constant.ALWAYS_SHOW
  }
];


export const MobMenu = [
  {
    name: 'Dashboard', router: '/mobile/dashboard', active: false, icon: 'sp-icon-clock', key: 'dashboard'
  },
  {
    name: 'Transactions',
    router: '/mobile/transactions',
    active: false,
    icon: 'sp-icon-transaction1',
    key: 'transaction_view_all'
  },
  {
    name: 'Users', router: '', active: false, icon: 'sp-icon-users2', key: '',
    child: [
      {name: 'Users', router: '/mobile/users', active: false, key: 'user_view_all'},
      {name: 'Roles', router: '/mobile/roles', active: false, key: 'role_view_all'}
    ]
  },
  {
    name: 'Merchant', router: '', active: false, icon: 'sp-icon-merchant1', key: '',
    child: [
      {name: 'Merchant Info', router: '/mobile/merchant/detail', active: false, key: 'merchant_view_detail'},
      {name: 'Receipt', router: '/mobile/merchant/receipt/details', active: false, key: 'merchant_receipt_view'},
      {name: 'Device', router: '/mobile/merchant/devices', active: false, key: 'merchant_terminal_view'},
      {name: 'Merchant List', router: '/mobile/merchant', active: false, key: Constant.ALWAYS_SHOW}
    ]
  },
  {
    name: 'Profile', router: '/mobile/profile', active: false, icon: 'sp-icon-user_edit', key: Constant.ALWAYS_SHOW
  }
];

export const BuildMenu = (authority) => {
  let judge = (item, children) => {
    // console.log('authority: ' + JSON.stringify(authority, null, 4));

    return (item.key === Constant.ALWAYS_SHOW || authority[item.key] || children.length);
  };
  return PushMenu(Menu, judge);
};

export const BuildMobMenu = (authority) => {
  let judge = (item, children) => {
    return (item.key === Constant.ALWAYS_SHOW || authority[item.key] || children.length);
  };
  return PushMenu(MobMenu, judge);
};

export const PushMenu = (menus, judge) => {
  let list = [];
  for (let item of menus) {
    let children = [];
    if (item.child) {
      children = PushMenu(item.child, judge);
    }
    if (judge(item, children)) {

      list.push({name: item.name, router: item.router, active: false, icon: item.icon, child: children,
        activeRouteList: item.activeRouteList|| []
      });
    }
  }
  return list;
};

export const FindFirstPage = (authority) => {
  let menus = BuildMenu(authority);
  let router = '';
  if (menus[0].child.length) router = menus[0].child[0].router;
  else router = menus[0].router;
  return router;
};
