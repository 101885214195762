import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[number]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputIntegerDirective),
      multi: true
    }
  ]
})

export class InputIntegerDirective implements ControlValueAccessor {

  @HostListener('input', ['$event']) onChange = (_: any) => {
  };

  @HostListener('blur', ['$event']) onTouched = (_: any) => {
  };

  get element() {
    return this.elementRef.nativeElement;
  }

  constructor(private elementRef: ElementRef, private _renderer: Renderer2) {
  }

  writeValue(value: string): void {
    this._renderer.setProperty(this.element, 'value', this.formatNumber(value));
  }

  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this.element, 'disabled', isDisabled);
  }

  registerOnChange(fn: any): void {
    this.onChange = (event) => {
      let data = event['data'];
      let value = event.target['value'];
      const price = this.formatNumber(value, data);

      this._renderer.setProperty(this.element, 'value', price);
      fn(price);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = (event) => {
      if(event.target['value']){ 
        let amount;
        if(event.target['value'] == '-')amount = '-';
        else amount= Number(event.target['value']) || 0;  
        let price = amount; 
        this._renderer.setProperty(this.element, 'value', price);
        fn(price);
      }
    };
  }

  formatNumber(number: string, data?: string) { 
    if (!number && number != '0') return '';
    let formatNumber: string = number.toString().replace(/[^\d-]/g, '');  

    if(data == '-'){
      let lst = formatNumber.substring(1, formatNumber.length)
      let nlst = lst.replace(/[^\d.]/g, '');
      formatNumber = formatNumber.substring(0, 1) + nlst;
    }

    let res 
    if(formatNumber != '-')res = Number(formatNumber)
    else res = '-';

    return res;
  }

}
