export const ValidatorsRules = {
    required: {
        message: 'Cannot be empty.'
    },
    email: {
        reg: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@([\da-zA-Z](-[\da-zA-Z])?)+(\.{1,2}[\da-zA-Z]+)$/,
        message: 'Email is invalid.'
    },
    password: {
        reg: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&(){}[\]:;<>,?\/~_+\-=|\\]).{8,32}$/,
        message: 'The password is too short or does not meet the other minimum requirements.'
    },
    passcode_4: {
        reg: /^([0-9]{4})?$/,
        message: 'The passcode must be 4 digital number.'
    },
    passcode_6: {
        reg: /^([0-9]{6})?$/,
        message: 'The passcode must be 6 digital number.'
    },
    compare: {
        message: 'The two inputs do not match'
    },
    comparePassword: {
        message: 'New Password does not match Confirm Password.'
    },
    comparePasscode: {
        message: 'New Passcode does not match Confirm Passcode.'
    },
    min: {
        message: 'The min value is {0}.'
    },
    max: {
        message: 'The max value is {0}.'
    },
    maxlength: {
        message: 'The max length is {0}.'
    },
    minlength: {
        message: 'The min length is {0}.'
    },
    specialValidator: {
        reg: /^[a-zA-Z0-9_ ]*$/,
        message: 'The value contains illegal characters.'
    },
    legal: {
        reg: /^[a-zA-Z0-9 `'"*.!@#$%^&(){}[\]:;,?\/~_+\-=|\\]*$/,
        message: 'The value contains illegal characters.'
    },
    Integer: {
        reg: /^-?[1-9]\d*$/,
        message: 'This value must be integer number'
    },
    PositiveInteger: {
        reg: /^[1-9]\d*$/,
        message: 'This value must be positive integer number'
    },
    illegal: {
        message: 'This value is illegal'
    },
    /*not use*/
    phone: {
        reg: /(^\(\d{3}\)\d{3}-\d{4}$)|(^((00|\+)?(86(?:-|)))?((\d{11})|(\d{3}[- ]{1}\d{4}[- ]{1}\d{4})|((\d{2,4}[- ]){1}(\d{7,8}|(\d{3,4}[- ]{1}\d{4}))([- ]{1}\d{1,4})?))$)/,  // 美国和中国
        message: 'Phone is not valid'
    },
    number: {
        reg: /^[0-9]*$/,
        message: 'This value must be number'
    },
    letter: {
        reg: /^[A-Za-z ]+$/,
        message: 'This value must be letter'
    },
    normalChar: {
        reg: /^[A-Za-z0-9]+$/,
        message: 'This value must be number or letter'
    },
    // legal: {
    //     reg: /^((?!'|\|--|\||\%7C).)*$/,
    //     message: 'This value cannot be \'\'\',\'+\',\'--\',\'|\',\'%7C\''
    // },
    length: {
        message: 'Character length is between {0} and {1}',
    },
    equal: {
        message: 'The data you entered is different from the first time.Please enter again'
    },
  numberRange: {
       message: 'Value is between {0} and {1}'
    },
  valueBig: {
       message: 'Value is too large'
    },
    url:{
        reg: new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([0-9]{1,3}\\.){3}[0-9]{1,3})|' + // ip address
        '([\\w-]+\\.)+[\\w-]+(/[\\w- ./?%&=]*)?)$', 'i'),// domian and path,
        message: 'This value must be a properly url'
    },
    specialChar: {
        reg: /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im,
        message: 'Value cannot contain special characters'
    }
};
