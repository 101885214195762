import {
  ComponentFactoryResolver, ComponentRef, EventEmitter, Directive, Input, OnChanges, OnInit, Type,
  ViewContainerRef, Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';


import { FormInputComponent } from '../form-input/form-input.component';
import { FormSelectComponent } from '../form-select/form-select.component';
import { FormCheckboxGroupComponent } from '../form-checkbox-group/form-checkbox-group.component';
import { FormSwicthComponent } from '../form-switch/form-switch.component';

import { Field } from '../../../models/field.interface';
import { SettingField  } from '../../../models/settingConfig';


const components: { [fieldType: string]: Type<Field> } = {
  '0': FormInputComponent,
  '1': FormSelectComponent,
  '2': FormCheckboxGroupComponent,
  '3': FormSwicthComponent
};

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {

  @Input()
  config: SettingField;

  @Input()
  group: FormGroup;

  @Input() fieldList: Array<SettingField>;

  @Input() submitted: boolean;

  component: ComponentRef<any>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
      this.component.instance.fieldList = this.fieldList;
      this.component.instance.submitted = this.submitted;
    
    }
  }

  ngOnInit() {
    if (!components[this.config.fieldType]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.fieldType}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(components[this.config.fieldType]);
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
    this.component.instance.fieldList = this.fieldList;
    this.component.instance.submitted = this.submitted;
  }
}
