import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Location} from '@angular/common';

@Directive({
  selector: 'button[goBack]'
})
export class BackDirective implements OnInit {

  @Input() goBack: string;

  constructor(private _renderer: Renderer2, private el: ElementRef, private location: Location) {

  }

  ngOnInit(): void {
    this.append(this.el.nativeElement);
    this._renderer.listen(this.el.nativeElement, 'click', () => {
      this.location.back();
    });
  }

  append(el) {
    if (this.goBack) {
      let _renderer = this._renderer;
      let small = _renderer.createElement('small');
      _renderer.addClass(small, 'sp-icon-back_L');
      _renderer.appendChild(el, small);
      let text = _renderer.createText(this.goBack);
      _renderer.appendChild(el, text);
    }
  }

}
