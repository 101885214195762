import {Component, OnInit, AfterViewInit, Inject} from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HeadTipService } from 'src/app/utils/headTip.service';


@Component({
  selector: 'head-tip-number',
  templateUrl: './head-tip-number.component.html',
  styleUrls: ['./head-tip-number.component.scss'],
})
export class HeadTipNumberComponent implements OnInit {

    tipString:string = '';
    userType:any = '';

    constructor(private router: Router, @Inject('options') private options) {
       
    }

    username = this.options.username;
    ngOnInit() {
    }

  goPage() {
    this.router.navigate(['/number-register'])
  }
}
