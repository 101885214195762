import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: 'button[type="submit"]'
})
export class DebounceSubmitDirective {

  private lastClickTime: number;

  constructor() {
  }

  @HostListener('click') onClick() {
    let now = new Date().getTime();
    if (!this.lastClickTime || now - this.lastClickTime > 1000) this.lastClickTime = new Date().getTime();
    else return false;
  }

}
