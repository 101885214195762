import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../models/field.interface';
import { SettingField  } from '../../../models/settingConfig';

@Component({
  selector: 'app-form-select',
  template: `
  <div class="form-group row" [formGroup]="group">
  <label class="up-form-label" [class.required]="config.required === 1">{{config.displayName}}</label>
  <div class="up-form-control">
  <select [formControlName]="config.fieldName" class="form-control" [validators]="group.controls[config.fieldName]?.errors" [validators_submitted]="submitted">
  <ng-container *ngFor="let option of config.selectList">
  <option [value]="option.val" >
  {{option.key}}
   </option>
  </ng-container>

  </select>
  </div>
</div>
  `,
  styles: [' .up-form-label {flex: 1;max-width: 100%;text-align: left !important;overflow: hidden;text-overflow:ellipsis; white-space: nowrap;}'],
})
export class FormSelectComponent implements Field {
  config: SettingField;
  group: FormGroup;
  submitted: boolean;
}
