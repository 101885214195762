import {HttpErrorResponse} from '@angular/common/http';

export class Response<T> {
  public metadata: any;
  public data: T;

  public resultCode: string;
  public resultMsg: string;

  public httpError: HttpErrorResponse;
  public flag: string;
  public ResultMsg: string;
  response: {};
}
