import { Component, OnInit,TemplateRef, Inject } from '@angular/core';
import { DialogService } from 'src/app/utils/dialog.service';
import { Modal } from 'src/app/utils/modal.service';

@Component({
  selector: 'app-res-dialog',
  templateUrl: './res-dialog.component.html',
  styleUrls: ['./res-dialog.component.scss']
})
export class ResDialogComponent implements OnInit {
  constructor(@Inject('options') private options) {
  }

  modal:Modal

  resMsg:string = '';
  showTime;

  //reqSrc='assets/imgs/icon_copy.png';
  iconList = [
    {
      type: 'success',
      src: 'assets/imgs/success.png'
    },
    {
      type: 'fail',
      src: 'assets/imgs/fail.png'
    },
    {
      type: 'prompts',
      src: 'assets/imgs/prompts.png'
    },
    {
      type: 'warnings',
      src: 'assets/imgs/warnings.png'
    }
  ]

  iconInfo

  ngOnInit() {
    const {res, resMsg, showTime} = this.options;
    this.iconInfo = this.iconList.find(e => e.type === res);
    this.resMsg = resMsg;
    this.showTime = showTime
    // this.close();
  }
}
