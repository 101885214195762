import { Directive, ElementRef, HostListener, forwardRef, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Directive({
  selector: '[inputLimit]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputLimitDirective),
      multi: true
    }
  ]
})
export class InputLimitDirective {

  @Input() inputLimit: string;


  @HostListener('input', ['$event.target.value']) onChange = (_: any) => {
  };

  @HostListener('blur', []) onTouched = () => {
  };


  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    if (!value) return '';
    if (value.toString().indexOf('$') !== -1) {
      value = value.toString().split('$')[1];
    }
    if (this.inputLimit === 'percent') {
      this.el.nativeElement.value = parseFloat(value).toFixed(3);
    }else this.el.nativeElement.value =  parseFloat(value).toFixed(2);
    
  }


  get element() {
    return this.el.nativeElement;
  }

  constructor(private el: ElementRef, private _renderer: Renderer2) { }

  writeValue(value: string): void {
    this._renderer.setProperty(this.element, 'value', this.formatInput(value, this.inputLimit));
  }

  registerOnChange(fn: any): void {
    this.onChange = (value: string) => {
      const val = this.formatInput(value, this.inputLimit);
      this._renderer.setProperty(this.element, 'value', val);
      fn(val);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  formatInput(val: string, replace: string) {
    if (!val) return '';
    if (replace === 'percent') return this.getRegexp(val, 3, 100);
    else return val;
  }

  getRegexp(val, num, max) {
    if (val < 0) val = 0;
    if (val > max) val = max;
    if (num === 0) {
      val = val.toString().replace(/[^\d]/g, '');  //清除“数字”和“.”以外的字符
    }
    val = val.toString().replace(/[^\d.]/g, '');  //清除“数字”和“.”以外的字符
    val = val.toString().replace(/\.{2,}/g, '.'); //只保留第一个. 清除多余的
    val = val.toString().replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    let reg = '^(\\-)*(\\d+)\\.(\\d{' + num + '}).*$';
    let regNew = new RegExp(reg);
    val = val.toString().replace(regNew, '$1$2.$3');
    if (val.indexOf('.') < 0 && val !== '') {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      val = parseFloat(val);
    }
    return val;
  }
}
